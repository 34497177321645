import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { IinitialState } from '../../../reducers';
import { FormGenerator, FormGroup } from 'react-reactive-form';
import { FormUtil } from '../../common/FormUtil';
import { WithCommonModal } from '../../common/WithCommonModal';
import { Button, Col } from 'react-bootstrap';
import {
    IproductInfo,
    IreportChapter,
    IreportTemplateChapter,
    IreportTemplate
} from '../../../models';
import {
    getAllChapters,
    createReportTemplateChapter,
    deleteReportTemplateChapter,
    getAllReportTemplateChapters
} from '../../../actions/manageReportActions';
import { toastr } from 'react-redux-toastr'; // For confirmation dialogs
import { AbstractControl } from 'react-reactive-form';
import { beginAjaxCall, endAjaxCall } from '../../../actions/ajaxStatusActions';

interface Iprops {
    closeModal: () => void;
    show: boolean;
    template: IreportTemplate | undefined;
    selectedChapter?: IreportTemplateChapter; // New prop for the selected chapter
    isAddingChapter: boolean; // Flag to determine add/edit mode
    updateTemplate: (template: IreportTemplate) => void;
}

interface IdispatchProps {
    productInfo: IproductInfo;
}

const ManageReportAddChapterModal: React.FC<Iprops &
    IdispatchProps &
    WithTranslation> = props => {
    const {
        t,
        show,
        closeModal,
        template,
        selectedChapter,
        isAddingChapter
    } = props;
    const formGroupRef = React.useRef<FormGroup | null>(null); // Reference for FormGroup

    const [chapters, setChapters] = React.useState<IreportChapter[]>([]);
    const [
        currentChapter,
        setCurrentChapter
    ] = React.useState<IreportChapter | null>();
    const [loading, setLoading] = React.useState<boolean>(true);
    const dispatch = useDispatch();

    // Form configuration
    const chapterFieldConfig = React.useMemo(
        () => ({
            controls: {
                chapter: {
                    render: isAddingChapter
                        ? FormUtil.Select
                        : FormUtil.TextInput,
                    meta: isAddingChapter
                        ? {
                              options: chapters.map(chapter => ({
                                  value: chapter.id,
                                  label: `${chapter.chapterName} - ${chapter.reportRevision}`
                              })),
                              label: 'Chapter Name',
                              colWidth: 12,
                              placeholder: 'Select a chapter',
                              name: 'chapter',
                              isClearable: true
                          }
                        : {
                              label: 'Chapter Name',
                              colWidth: 12,
                              placeholder: 'Chapter Name',
                              name: 'chapter',
                              readOnly: true // Make the text input read-only in edit mode
                          },
                    formState: {
                        value: isAddingChapter
                            ? undefined
                            : `${selectedChapter?.reportChapter?.chapterName} - ${selectedChapter?.reportChapter?.reportRevision}`, // Display the chapter name in edit mode
                        disabled: !isAddingChapter // Keep it disabled in edit mode for the dropdown
                    }
                }
            }
        }),
        [isAddingChapter, chapters, selectedChapter]
    );

    const setForm = (form: AbstractControl) => {
        formGroupRef.current = form as FormGroup;

        // Subscribe to value changes in the form and explicitly type `values`
        formGroupRef.current?.valueChanges.subscribe((values: any) => {
            if (values.chapter) {
                setCurrentChapter(
                    chapters.find(
                        chapter => chapter.id === values?.chapter?.value
                    )
                );
            }
        });
    };

    const fetchChapters = async () => {
        try {
            const data = await getAllChapters();
            setChapters(data); // Set fetched chapters to state
        } catch (error) {
            console.error('Failed to fetch chapters:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle form submission (used in Add mode)
    const handleFormSubmit = async () => {
        if (!formGroupRef.current) {
            console.error('Form group is not initialized');
            return;
        }

        const chapterData: IreportTemplateChapter = {
            reportTemplateID: template?.id || '',
            reportChapterId: currentChapter?.id || '',
            order: 0
        };

        dispatch(beginAjaxCall()); // Dispatch an action to show a loader

        await createReportTemplateChapter(chapterData)
            .then(async () => {
                // get updated chapters
                let chapters: IreportTemplateChapter[] = await getAllReportTemplateChapters(
                    template?.id || ''
                );
                // Update the template with the new chapters
                props.updateTemplate({
                    ...template!,
                    reportTemplateChapters: chapters
                });

                props.closeModal();
            })
            .finally(() => {
                dispatch(endAjaxCall()); // Dispatch an action to hide the loader
            });
    };

    // Show confirmation dialog using toastr
    const handleDeleteClick = () => {
        toastr.confirm('Are you sure you want to delete this chapter?', {
            onOk: async () => {
                try {
                    // Call the delete function when the user confirms
                    if (selectedChapter && template) {
                        const reportTemplateChapter: IreportTemplateChapter = {
                            reportChapterId: selectedChapter?.reportChapter?.id,
                            order: 0,
                            reportTemplateID: template.id // Using the template object to get reportTemplateID
                        };

                        // Pass the full IreportTemplateChapter object with the template info
                        await deleteReportTemplateChapter(
                            reportTemplateChapter
                        );

                        const data = await getAllReportTemplateChapters(
                            template?.id || ''
                        );

                        props.updateTemplate({
                            ...template,
                            reportTemplateChapters: data
                        }); // Update the template with fetched chapters

                        props.closeModal(); // Close the modal after successful deletion
                    }
                } catch (error) {
                    console.error('Error while deleting the chapter:', error);
                }
            },
            onCancel: () => {
                console.log('Delete cancelled');
            },
            okText: t('Ok'),
            cancelText: t('Cancel')
        });
    };

    React.useEffect(() => {
        fetchChapters(); // Fetch chapters on component mount
    }, []);

    React.useEffect(() => {
        console.log('selectedChapter:', props.selectedChapter);
        if (props.selectedChapter) {
            setCurrentChapter(props.selectedChapter.reportChapter);
        }
    }, [props.selectedChapter]);

    if (!show) {
        return null; // Return null if the modal is not displayed
    }

    return (
        <div className="manage-report-template-modal">
            <div className="manage-template-form-container">
                <FormGenerator
                    onMount={setForm}
                    fieldConfig={chapterFieldConfig}
                />

                {currentChapter && (
                    <div className="chapter-details">
                        <p>
                            <strong>Report #:</strong>{' '}
                            {currentChapter.reportRevision}
                        </p>
                        <p>
                            <strong>Chapter Description:</strong>{' '}
                            {currentChapter.description}
                        </p>
                        <p>
                            <strong>Category:</strong> {currentChapter.category}
                        </p>
                        <p>
                            <strong>File Path:</strong>{' '}
                            {currentChapter.templateFileName}
                        </p>
                        <p>
                            <strong>Revision:</strong> {currentChapter.revision}
                        </p>
                    </div>
                )}
            </div>

            <Col xs={12} className="form-buttons text-right">
                <Button
                    bsStyle="default"
                    type="button"
                    className="pull-left"
                    onClick={closeModal}
                >
                    {t('cancel')}
                </Button>

                {isAddingChapter ? (
                    // Show Save button in Add mode
                    <Button
                        bsStyle="primary"
                        type="submit"
                        onClick={handleFormSubmit} // Trigger save on click
                    >
                        {t('Save')}
                    </Button>
                ) : (
                    // Show Delete button in Edit mode
                    <Button
                        bsStyle="warning"
                        type="button"
                        onClick={handleDeleteClick} // Trigger toastr confirmation on delete click
                    >
                        {t('Delete')}
                    </Button>
                )}
            </Col>
        </div>
    );
};

const mapStateToProps = (state: IinitialState) => {
    return {
        productInfo: state.productInfo
    };
};

export default withTranslation('manageReport')(
    connect(mapStateToProps, {})(WithCommonModal(ManageReportAddChapterModal))
);
