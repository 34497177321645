import React, { useEffect, useMemo, useState } from 'react';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';
import 'react-table/react-table.css'; // Ensure to import the CSS for styling
import { IreportTemplate } from '../../models';
import { jobTypesIdEnumInverse } from '../../models-enums';
import { useSelector } from 'react-redux';
import { getProductInfo } from '../../reducers/productInfoReducer';
import { withTranslation, WithTranslation } from 'react-i18next';

interface IReportTemplateGridProps {
    tableData: IreportTemplate[];
    rowClickHandler: (template: IreportTemplate) => void;
    filterValues: {
        brand: string | null;
        jobType: string | null;
        default: boolean | null;
    }; // Added filterValues prop
}

interface RowInfoReport extends RowInfo {
    original: IreportTemplate;
}

const ManageReportTemplateGrid: React.FC<IReportTemplateGridProps &
    WithTranslation> = ({
    t,
    rowClickHandler,
    filterValues, // Use the passed filterValues prop
    tableData
}) => {
    const [pages, setPages] = useState<number>(0);
    const [pageSize] = useState<number>(10); // Number of rows per page
    const [currentPage, setCurrentPage] = useState<number>(0);
    const productInfo = useSelector(getProductInfo);

    const handlePageChange = (pageIndex: number) => {
        setCurrentPage(pageIndex); // Update current page
    };

    // Filter templates based on filterValues
    const filteredTemplates = useMemo(() => {
        return tableData?.filter(template => {
            const matchesBrand =
                !filterValues.brand || template.brandID === filterValues.brand;
            const matchesJobType =
                !filterValues.jobType ||
                template.jobTypeID === filterValues.jobType;
            const matchesIsDefault =
                filterValues.default === null ||
                template.isDefault === filterValues.default;

            return matchesBrand && matchesJobType && matchesIsDefault;
        });
    }, [tableData, filterValues]);

    const getTdProps = (
        finalState: FinalState,
        rowInfo: RowInfoReport | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        return {
            onClick: (e: React.MouseEvent<HTMLFormElement>) => {
                if (rowInfo) {
                    rowClickHandler(rowInfo.original);
                }
            }
        };
    };

    const columns = [
        {
            Header: t('HeaderTemplateName'),
            accessor: 'templateName'
        },
        {
            id: 'jobType',
            Header: t('HeaderJobType'),
            accessor: (row: IreportTemplate) => {
                return t(
                    `nsJob:${
                        jobTypesIdEnumInverse[
                            row.jobTypeID as keyof typeof jobTypesIdEnumInverse
                        ]
                    }`
                );
            }
        },
        {
            id: 'brand',
            Header: t('HeaderBrand'),
            accessor: (row: IreportTemplate) => {
                return productInfo?.allBrands[row.brandID]?.name;
            }
        },
        {
            Header: t('HeaderIsDefault'),
            accessor: 'isDefault', // Ensure this matches the key in your data
            Cell: ({ value }: { value: boolean }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>{value ? 'Yes' : 'No'}</span>
                </div>
            )
        }
    ];

    return (
        <div className="report-template-grid-container">
            <ReactTable
                data={filteredTemplates} // Use filtered templates
                columns={columns}
                pageSize={pageSize} // Adjust the number of rows to show per page
                pages={pages} // Total number of pages
                manual // Control the pagination manually
                page={currentPage}
                onPageChange={handlePageChange} // Update page on change
                className="beacon-table -highlight" // Optional table styling
                previousText="Previous"
                nextText="Next"
                showPagination={true} // Show pagination controls
                noDataText={t('GridNoData')} // Message when there's no data
                resizable={false} // Disable column resizing
                getTrProps={getTdProps}
            />
        </div>
    );
};

export default withTranslation('manageReportBuilder')(ManageReportTemplateGrid);
