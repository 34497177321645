import {
    Icountry,
    IparentTile,
    Itile,
    Iuser,
    transitionInType,
    transitionOutType,
    IoptionNumberValue,
    Ioption
} from '../models';
import { MSAL_SCOPES, msalApp } from '../components/auth/Auth-Utils';
import {
    DocumentGroups,
    jobTypesIdEnumForSelect,
    measurementPointListTypeEnum,
    menuParentKeysEnum
} from '../models-enums';

import { emptyTile } from '../reducers/initialState';
import { find, intersectionBy } from 'lodash';
import { toastr } from 'react-redux-toastr';
import { securityFunctions } from './securityFunctions';
import { tiles } from './tiles';
import { TFunction } from 'i18next';
import { tutorial } from './tutorials';
import { FormUtil } from '../components/common/FormUtil';

const MPLTypebyJobTypesID: { [key: string]: number } = {
    '80eedbac-ec22-45ef-9ac7-f2eb4be2db4c': 0, // Audit job does not have MPLs
    '524235fd-4633-4b7a-9c13-c37fc39efe69': measurementPointListTypeEnum.annual,
    '9c7fde18-0b94-4af8-b4aa-913c40e5aed0':
        measurementPointListTypeEnum.verification,
    'ae78eaa3-89c2-490a-90c6-44e5cfa10b01': measurementPointListTypeEnum.annual,
    'bbfd50fe-a790-4384-8a29-00995e5d7158':
        measurementPointListTypeEnum.agsRebalancing
};
const jobCommentTypeOptions = [
    { value: true, label: 'Deficiency' },
    { value: false, label: 'Info' }
];

const measurementPointTypeEnum: { [key: number]: string } = {
    1: 'Pass/Fail',
    2: 'Text',
    3: 'Numeric',
    4: 'Select',
    6: 'Date',
    7: 'Long Text',
    8: 'Summary Page'
};
const measurementPointTypeOptions = [
    { label: 'Pass/Fail', value: 1 },
    { label: 'Text', value: 2 },
    { label: 'Numeric', value: 3 },
    { label: 'Select', value: 4 },
    { label: 'Date', value: 6 },
    { label: 'Long Text', value: 7 },
    { label: 'Summary Page', value: 8 }
];
const measurementPointTypes: { [key: string]: number } = {
    MEASUREMENT_POINT_PASSFAIL: 1,
    MEASUREMENT_POINT_TEXT: 2,
    MEASUREMENT_POINT_NUMERIC: 3,
    MEASUREMENT_POINT_SELECT: 4,
    GROUP: 5,
    MEASUREMENT_POINT_DATE: 6,
    MEASUREMENT_POINT_LONGTEXT: 7,
    MEASUREMENT_POINT_SUMMARYPAGE: 8
};
const measurementPointTypesInverse: { [key: number]: string } = {
    1: 'Pass/Fail',
    2: 'Text',
    3: 'Numeric',
    4: 'Select',
    5: 'Group',
    6: 'Date',
    7: 'Long Text',
    8: 'Summary Page'
};
const measurementPointPassFailTypes: { [key: string]: number } = {
    pass: 1,
    fail: 2,
    notApplicable: 3
};

const measurementPointPassFailOptions = [
    { label: 'Pass', value: 1 },
    { label: 'Fail', value: 2 },
    { label: 'Not Applicable', value: 3 }
];
const isResolvedOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' }
];

const colors = {
    green: '#7FC467',
    greenButton: 'success', // use the bootstrap success button color
    greenTr: 'rgba(127, 196, 103,.2)',
    greenBanner: 'rgba(127, 196, 103, .85)',
    blue: '#027AC3',
    teal: '#0c5a8b',
    blueTr: 'rgba(2,122,195,.2)',
    blueBanner: 'rgba(2,122,195,.85)',
    blueButton: 'primary',
    darkButton: 'primary',
    greyButton: 'default',
    iconBlue: ' #336eb4',
    orange: '#ff9738',
    orangeTr: 'rgba(255,166,99,.2)',
    orangeBanner: 'rgba(255,166,99,.85)',
    orangeButton: 'warning',
    lightTeal: '#06B6B6',
    purple: '#06B6B6',
    purpleBanner: 'rgba(6, 182, 182, .85)',
    purpleButton: 'info', // use the bootstrap info button color
    purpleTr: 'rgba(6, 182, 182,.2)',
    lightTealBanner: 'rgba(6, 182, 182, .85)',
    dark: '#013564',
    darkBanner: 'rgba(1, 53, 100, .85)',
    red: '#d00000',
    grey: '#A2ACB4',
    greyBanner: 'rgba(162, 172, 180,.85)',
    greyText: '#AAAAAA',
    darkGreyText: '#888888',
    darkRed: '#840000',
    darkGrey: '#707070',
    greyBlueShadow: '#3b5161',
    textBlue: '#007AC3',
    greyBlue: '#7094ad',
    darkerBlue: '#306BA0',
    yellow: '#F1E43B'
};

const installBaseStatusColorLookup = {
    'Not Tested': colors.greyBlue,
    Pass: colors.green,
    Fail: colors.red,
    'Cannot Complete': colors.purple,
    // Repair: colors.textBlue,
    Repaired: colors.textBlue
};

const alertTypes = [
    { value: 'alert', label: 'Alert' },
    { value: 'informational', label: 'Informational' }
];

const menuItemsWithChildren: IparentTile[] = [
    {
        title: 'dashboard',
        key: menuParentKeysEnum.dashboard,
        url: '/dashboard',
        svgKey: 'dashboard',
        order: 10
    },
    {
        title: 'admin',
        key: menuParentKeysEnum.admin,
        url: '/admin',
        svgKey: 'admin',
        order: 100
    },
    {
        title: 'facility',
        key: menuParentKeysEnum.facility,
        url: '/facility',
        svgKey: 'facility',
        order: 110
    },
    {
        title: 'sales',
        key: menuParentKeysEnum.sales,
        url: '/sales',
        svgKey: 'sales',
        order: 120
    }
];

const inventoryImportAllowedExtensions = '.xlsx';

// not exhaustive!
export const standards = {
    NFPA99_2012: 'A7E05021-18F5-4D20-B733-9E49265B598A',
    NFPA99_2015: '6490C3E9-B9D5-419C-8BF5-9A900956AB79',
    NFPA99_2018: '740E2F29-6BFA-4316-98C1-F2B32637BF6E',
    CSA: '6988A779-8887-473E-AB63-C11E53EFDFE2'
};

export const usStandards = [
    standards.NFPA99_2012,
    standards.NFPA99_2015,
    standards.NFPA99_2018
];
export const caStandards = [standards.CSA];

export const constants = {
    toastrError: {
        transitionIn: 'bounceInDown' as transitionInType,
        transitionOut: 'bounceOutUp' as transitionOutType,
        timeOut: 8000
    },
    toastrWarning: {
        transitionIn: 'bounceInDown' as transitionInType,
        transitionOut: 'bounceOutUp' as transitionOutType,
        timeOut: 8000
    },
    toastrSuccess: {
        transitionIn: 'bounceInDown' as transitionInType,
        transitionOut: 'bounceOutUp' as transitionOutType,
        timeOut: 3000
    },
    inventoryImportAllowedExtensions,
    alertTypes,
    colors,
    tutorial,
    securityFunctions,
    tiles,
    menuItemsWithChildren,
    jobCommentTypeOptions,
    measurementPointListTypeEnum,
    MPLTypebyJobTypesID,
    measurementPointTypeEnum,
    measurementPointTypeOptions,
    measurementPointTypes,
    measurementPointTypesInverse,
    measurementPointPassFailOptions,
    measurementPointPassFailTypes,
    isResolvedOptions,
    installBaseStatusColorLookup,
    // eslint-disable-next-line no-shadow
    hasSecurityFunction: (
        user: Iuser,
        securityFun: string[],
        options?: { matchAll: boolean }
    ): boolean => {
        const sFunctions = user.securityFunctions.map(sf => sf.toUpperCase());
        const upperCase = (st: string) => st.toUpperCase();
        const intersection = intersectionBy(sFunctions, securityFun, upperCase);
        return options?.matchAll
            ? intersection.length === securityFun.length
            : intersection.length > 0;
    },
    getTileByURL: (URL: string): Itile => {
        const firstParam = '/' + URL.split('/')[1];
        const foundTile = find(tiles, { url: firstParam });
        if (foundTile && foundTile.url.length) {
            return foundTile as Itile;
        } else {
            return emptyTile;
        }
    },

    constructSystems: (t: TFunction): IoptionNumberValue[] => {
        let systemsOptions: IoptionNumberValue[] = [];
        for (let i = 1; i < 41; i++) {
            systemsOptions = [
                ...systemsOptions,
                {
                    label: t('manageInventory:systemsOptionLabel', {
                        count: i
                    }),
                    value: i
                }
            ];
        }
        return systemsOptions;
    },

    countries: require('../constants/countries.json') as {
        [key: string]: Icountry;
    },
    handleError(error: any, message: string) {
        let msg = '';

        if (error && error.response && error.response.data) {
            // get error message from the JSON body
            const messageDetail =
                error.response.data instanceof Object &&
                error.response.data.hasOwnProperty('value')
                    ? error.response.data.value
                    : error.response.data;
            msg = `Failed to ${message}. ${messageDetail}`;
        } else if (error && error.message) {
            // get error message from the error.message
            msg = `Failed to ${message}. ${error.message}`;
        } else {
            // show an error without any additional details
            msg = `Failed to ${message}.  Please try again or contact support.`;
        }
        if (!navigator.onLine) {
            // override and show an error if we are not connected to the internet
            msg = 'Please connect to the internet.';
        }

        if (error && error.errorCode === 'user_login_error') {
            // override login errors and do not show an error
            setTimeout(() => {
                msalApp?.loginRedirect({
                    scopes: [MSAL_SCOPES.MMG]
                });
            }, 1000);
            return;
        }

        if (error && error.response && error.response.status === 401) {
            console.error(
                'catching unauthorized, we should not get here now that we are using msalFetch'
            );
            return; // don't show an error
        }
        // show friendly timeout errors
        if (error.code === 'ECONNABORTED') {
            msg =
                'MyMedGas is having trouble connecting to the internet. Please refresh and try again.';
        }

        toastr.error('Error', msg, constants.toastrError);
    },
    tableSearchDebounceTime: 500,
    formDebounceTime: 300,
    timedQuizHours: 4,
    searchProductPageCount: 80, // max items to show inside the product search modal
    searchProductRecentProductLimit: 30,
    tablePageSizeDefault: 25,
    tablePageSizeOptions: [10, 25, 50, 100],
    httpTimeout: 60000,
    httpTimeoutDownload: 1000 * 60 * 5, // 5 min
    trainingVideoProgressSaveDelay: 15000,
    cacheFseUsersMinutes: 10,
    defaultStandardID: standards.NFPA99_2018,
    defaultStandardUS: standards.NFPA99_2012,
    defaultStandardUK: '',
    momentSQLFormat: 'YYYY-MM-DDTHH:mm:ss',
    momentDisplayFormat: 'DD-MMM-YY',
    momentDisplayFormatWithTime: 'DD-MMM-YY LT',
    momentDisplayFormatWithTimeAndSeconds: 'DD-MM-YY LTS',
    defaultCountryID: 'abc5d95c-129f-4837-988c-0bf4ae1f3b67',
    ukCountryID: '419ec375-dce2-41fb-8251-ce28caa507f5',
    usCountryID: 'abc5d95c-129f-4837-988c-0bf4ae1f3b67',
    canadaCountryID: '2fcbfdc4-9c25-4b58-874c-7c2625eaa750',
    germanyCountryID: '049060ff-8fc7-44b3-9c0e-20a987aac7ff',
    malaysiaCountryID: '0b61d1dd-f32e-442c-9ff4-fb5fc1e012e4',
    categoryManifoldID: '55ec8036-f4cb-4fc9-8ed7-87eaaed8df13',
    virtualProductSubcategoryID: 'cad4e18c-45d6-4e42-8a27-090bf385e723',
    bulkSelectionLimit: 400,
    photoUploadTimeout: 1500,
    urlValidatorPattern: /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
};

export const capitalEquipmentCategories = {
    agss: 'c17ea749-20b7-4ab7-ab15-237a85a92e83',
    airSystem: 'aec75f68-6836-4af1-89cf-75d9232a8d7b',
    manifold: '55ec8036-f4cb-4fc9-8ed7-87eaaed8df13',
    oxygenSystem: '1a28be85-84ab-40bd-840f-e32dcffaad4c',
    vacuumWagd: '97bae3c8-1adc-4429-8bf0-68f47618cd03'
};

export const sapBusinessIndicators = {
    fixedPrice: 'FP',
    servicePlan: 'SP',
    mf: 'MF',
    fm: 'FM',
    ic: 'IC'
};

export const jobTypeOptions = FormUtil.convertEnumToOptions(
    Object.keys(jobTypesIdEnumForSelect)
        .filter(
            key =>
                jobTypesIdEnumForSelect[
                    key as keyof typeof jobTypesIdEnumForSelect
                ] !== jobTypesIdEnumForSelect.nonProductive
        )
        .reduce((obj, key) => {
            obj[key as keyof typeof jobTypesIdEnumForSelect] =
                jobTypesIdEnumForSelect[
                    key as keyof typeof jobTypesIdEnumForSelect
                ];
            return obj;
        }, {} as { [key: string]: string })
);

export const jobTypeOptionsTranslated = (t: TFunction) => {
    return jobTypeOptions.map(option => {
        return {
            ...option,
            label: t(`nsJob:${option.label}`)
        };
    });
};

export const postalAndStateRequiredCountries = {
    [constants.usCountryID]: { postalCode: true, state: true },
    [constants.ukCountryID]: { postalCode: true, state: false },
    [constants.germanyCountryID]: { postalCode: true, state: false },
    [constants.canadaCountryID]: { postalCode: true, state: true },
    [constants.malaysiaCountryID]: { postalCode: false, state: false }
};

export const productPlaceholderImages = [
    {
        categoryId: 'C17EA749-20B7-4AB7-AB15-237A85A92E83',
        src: 'AGS System_D2.png'
    },
    {
        categoryId: 'AEC75F68-6836-4AF1-89CF-75D9232A8D7B',
        src: 'Medical Air Systems (Air).png'
    },
    {
        categoryId: 'AEE5775D-FD2D-4831-9D68-80090676EF5F',
        src: 'Monitoring.png'
    },
    {
        categoryId: '3C4D2DC0-0CCD-4281-861D-F07BCCA8F6F7',
        src: 'Monitoring.png'
    },
    {
        categoryId: '6E817F59-CF14-42ED-89C9-9E99C15E6CA3',
        src: 'Monitoring.png'
    },
    {
        categoryId: '699C584F-F431-432B-A251-FF8E3DC82D96',
        src: 'Patient Environment-01.png'
    },
    {
        categoryId: 'EA5E3BBE-9D11-4F8A-B9BA-8263829B1BF4',
        src: 'Valves.png'
    },
    {
        categoryId: '55EC8036-F4CB-4FC9-8ED7-87EAAED8DF13',
        src: 'manifold.png'
    },
    {
        categoryId: '59D4F580-40A8-4084-A2C3-AE5B08C9718E',
        src: 'Gas outlets.png'
    },
    {
        categoryId: '97BAE3C8-1ADC-4429-8BF0-68F47618CD03',
        src: 'Medical Vacuum-01.png'
    },
    {
        categoryId: '0C3C9A37-5B98-4B7D-8141-384747DA373C',
        src: 'Valves.png'
    },
    {
        categoryId: 'A142693B-0272-4673-98FF-B76DB5814023',
        src: 'Valves.png'
    },
    {
        categoryId: '5CD2F63A-2219-42CF-B613-52C24067B2E9',
        src: '06-Liquid Oxygen System (Oxygen).png'
    },
    {
        categoryId: 'A8C77FFB-134A-412D-B763-311E18255CE5',
        src: 'Medical Pipeline-01.png'
    },
    {
        categoryId: '1A28BE85-84AB-40BD-840F-E32DCFFAAD4C',
        src: '08-Oxygen Concentrator Systems (Oxygen).png'
    },
    {
        categoryId: 'F045DE0B-0B30-4D57-BBAD-EF5C54922B81',
        src: '01-Pipeline Components.png'
    }
];

export const DOCUMENT_TYPE_VISIT_REPORT =
    '33B984F9-AD87-4D4C-A774-1181AA40D29E';

// This is not all, only really need this Job one right now
export const mainCategories: { [key: string]: string } = {
    job: 'ee4e20f7-2541-414e-8810-dd53dc3064ea'
};

export enum DocumentTypesEnum {
    AGSReblancing = '1003AB57-F3EE-463A-AC8C-DFA8A8901A31',
    AGSSSchematic = 'A0076422-4776-43A7-88C9-190D350312FF'
}

export const documentTypeMapping: { [key: string]: DocumentGroups } = {
    '1003AB57-F3EE-463A-AC8C-DFA8A8901A31': DocumentGroups.reports, // AGS Re-Balancing Reports,
    'A0076422-4776-43A7-88C9-190D350312FF': DocumentGroups.reports, // AGSS Schematic,
    // 'EE7C0746-6C28-4386-9655-2964FCF498B3': DocumentGroups.other, // Air Sample
    '63B549F7-5338-490A-98A8-0D783E1EF20E': DocumentGroups.reports, // Annual Inspection Reports
    '2CB24522-2066-43F0-9F9A-6020E15A328B': DocumentGroups.reports, // Budget Planning Reports
    '6BD51679-A32D-4597-9F51-BA9B50262C4A': DocumentGroups.leaglAndQSHE, // Certification
    '09BE932A-149F-4C32-988A-A4ECBCCDC958': DocumentGroups.reports, // Commissioning Reports
    'A60A2CBB-55AB-4045-B664-CA370A244F7A': DocumentGroups.leaglAndQSHE, // CP / AP Certificate
    '1A375BE0-C705-4F2C-A1D3-0552D129739B': DocumentGroups.techSupport, // Drawings
    '96DAF302-5243-447B-8063-5E3D07354A46': DocumentGroups.leaglAndQSHE, // Employers Liability
    '935D067A-9E4B-4670-935E-5EA68933A275': DocumentGroups.reports, // Environment of Care Report
    '984BC9ED-42B0-4242-8482-88DFBF8771A6': DocumentGroups.reports, // Job Comment Reports
    '9F34E464-B9C5-4BA9-91F7-925F17ED420F': DocumentGroups.techSupport, // Manuals
    // '22D50AA2-31A3-4632-8B4E-514FB6D57602': DocumentGroups.other, // Oil Sample
    // '87400BA8-6729-410A-9C23-94C3436A7489': DocumentGroups.other, // Other
    // 'C0DAB37A-1F84-4BCB-80E8-949E1F698A48': DocumentGroups.other, // Permit to Work
    'E8C6ABB1-4FC4-4D01-AC1C-647C24563BEF': DocumentGroups.techSupport, // Photos
    'E1F3E245-86AD-47C0-ACAE-10DDC88D60BE': DocumentGroups.leaglAndQSHE, // Public and Product Liability
    'BE17D9FE-B214-446C-9570-2D631504F8E4': DocumentGroups.financeAndPlanning, // Quotes
    // '4177CDB3-AF60-4377-BD79-8ADDB77A4B35': DocumentGroups.other, // Risk Assessment / Method Statement
    'DC92BF93-F5DA-4F5C-A43A-847495B885C3': DocumentGroups.leaglAndQSHE, // Safety Plans
    '293B7A6E-A49F-4DDD-A9F0-5E72419D80C8': DocumentGroups.reports, // Site Survey (Audit) Reports
    // '4E83B822-900D-4A2A-94E9-77B710AE9EA2': DocumentGroups.other, // Submittal
    '7E024A9D-DBC9-4A44-9703-287C085FC375': DocumentGroups.reports, // Training Records
    'FC0BECA6-73FC-4CD0-988A-20DA5DF6F2AB': DocumentGroups.reports, // Verification Reports
    '33B984F9-AD87-4D4C-A774-1181AA40D29E': DocumentGroups.reports, // Visit Reports
    '40803CEF-744A-4DDC-A4DB-4A93E38304CA': DocumentGroups.leaglAndQSHE, // Standards and other Legal Docs
    'BFBDC78C-17E0-4FEE-BB8E-862E062F0615': DocumentGroups.reports // Daily Rounds Reports
};

export const countryCodeOptions: Ioption[] = [
    { label: 'Afghanistan +93', value: '93' },
    { label: 'Aland Islands +358', value: '358' },
    { label: 'Albania +355', value: '355' },
    { label: 'Algeria +213', value: '213' },
    { label: 'American Samoa +1684', value: '1684' },
    { label: 'Andorra +376', value: '376' },
    { label: 'Angola +244', value: '244' },
    { label: 'Anguilla +1264', value: '1264' },
    { label: 'Antarctica +672', value: '672' },
    { label: 'Antigua and Barbuda +1268', value: '1268' },
    { label: 'Argentina +54', value: '54' },
    { label: 'Armenia +374', value: '374' },
    { label: 'Aruba +297', value: '297' },
    { label: 'Australia +61', value: '61' },
    { label: 'Austria +43', value: '43' },
    { label: 'Azerbaijan +994', value: '994' },
    { label: 'Bahamas +1242', value: '1242' },
    { label: 'Bahrain +973', value: '973' },
    { label: 'Bangladesh +880', value: '880' },
    { label: 'Barbados +1246', value: '1246' },
    { label: 'Belarus +375', value: '375' },
    { label: 'Belgium +32', value: '32' },
    { label: 'Belize +501', value: '501' },
    { label: 'Benin +229', value: '229' },
    { label: 'Bermuda +1441', value: '1441' },
    { label: 'Bhutan +975', value: '975' },
    { label: 'Bolivia +591', value: '591' },
    { label: 'Bonaire, Sint Eustatius and Saba +599', value: '599' },
    { label: 'Bosnia and Herzegovina +387', value: '387' },
    { label: 'Botswana +267', value: '267' },
    { label: 'Bouvet Island +55', value: '55' },
    { label: 'Brazil +55', value: '55' },
    { label: 'British Indian Ocean Territory +246', value: '246' },
    { label: 'Brunei Darussalam +673', value: '673' },
    { label: 'Bulgaria +359', value: '359' },
    { label: 'Burkina Faso +226', value: '226' },
    { label: 'Burundi +257', value: '257' },
    { label: 'Cambodia +855', value: '855' },
    { label: 'Cameroon +237', value: '237' },
    { label: 'Cape Verde +238', value: '238' },
    { label: 'Cayman Islands +1345', value: '1345' },
    { label: 'Central African Republic +236', value: '236' },
    { label: 'Chad +235', value: '235' },
    { label: 'Chile +56', value: '56' },
    { label: 'China +86', value: '86' },
    { label: 'Christmas Island +61', value: '61' },
    { label: 'Cocos (Keeling) Islands +672', value: '672' },
    { label: 'Colombia +57', value: '57' },
    { label: 'Comoros +269', value: '269' },
    { label: 'Congo +242', value: '242' },
    { label: 'Congo, Democratic Republic of the Congo +242', value: '242' },
    { label: 'Cook Islands +682', value: '682' },
    { label: 'Costa Rica +506', value: '506' },
    { label: "Cote D'Ivoire +225", value: '225' },
    { label: 'Croatia +385', value: '385' },
    { label: 'Cuba +53', value: '53' },
    { label: 'Curacao +599', value: '599' },
    { label: 'Cyprus +357', value: '357' },
    { label: 'Czech Republic +420', value: '420' },
    { label: 'Denmark +45', value: '45' },
    { label: 'Djibouti +253', value: '253' },
    { label: 'Dominica +1767', value: '1767' },
    { label: 'Dominican Republic +1809', value: '1809' },
    { label: 'Ecuador +593', value: '593' },
    { label: 'Egypt +20', value: '20' },
    { label: 'El Salvador +503', value: '503' },
    { label: 'Equatorial Guinea +240', value: '240' },
    { label: 'Eritrea +291', value: '291' },
    { label: 'Estonia +372', value: '372' },
    { label: 'Ethiopia +251', value: '251' },
    { label: 'Falkland Islands (Malvinas) +500', value: '500' },
    { label: 'Faroe Islands +298', value: '298' },
    { label: 'Fiji +679', value: '679' },
    { label: 'Finland +358', value: '358' },
    { label: 'France +33', value: '33' },
    { label: 'French Guiana +594', value: '594' },
    { label: 'French Polynesia +689', value: '689' },
    { label: 'French Southern Territories +262', value: '262' },
    { label: 'Gabon +241', value: '241' },
    { label: 'Gambia +220', value: '220' },
    { label: 'Georgia +995', value: '995' },
    { label: 'Germany +49', value: '49' },
    { label: 'Ghana +233', value: '233' },
    { label: 'Gibraltar +350', value: '350' },
    { label: 'Greece +30', value: '30' },
    { label: 'Greenland +299', value: '299' },
    { label: 'Grenada +1473', value: '1473' },
    { label: 'Guadeloupe +590', value: '590' },
    { label: 'Guam +1671', value: '1671' },
    { label: 'Guatemala +502', value: '502' },
    { label: 'Guinea +224', value: '224' },
    { label: 'Guinea-Bissau +245', value: '245' },
    { label: 'Guyana +592', value: '592' },
    { label: 'Haiti +509', value: '509' },
    { label: 'Holy See (Vatican City State) +39', value: '39' },
    { label: 'Honduras +504', value: '504' },
    { label: 'Hong Kong +852', value: '852' },
    { label: 'Hungary +36', value: '36' },
    { label: 'Iceland +354', value: '354' },
    { label: 'India +91', value: '91' },
    { label: 'Indonesia +62', value: '62' },
    { label: 'Iran, Islamic Republic of +98', value: '98' },
    { label: 'Iraq +964', value: '964' },
    { label: 'Ireland +353', value: '353' },
    { label: 'Israel +972', value: '972' },
    { label: 'Italy +39', value: '39' },
    { label: 'Jamaica +1876', value: '1876' },
    { label: 'Japan +81', value: '81' },
    { label: 'Jordan +962', value: '962' },
    { label: 'Kazakhstan +7', value: '7' },
    { label: 'Kenya +254', value: '254' },
    { label: 'Kiribati +686', value: '686' },
    { label: "Korea, Democratic People's Republic of +850", value: '850' },
    { label: 'Korea, Republic of +82', value: '82' },
    { label: 'Kosovo +383', value: '383' },
    { label: 'Kuwait +965', value: '965' },
    { label: 'Kyrgyzstan +996', value: '996' },
    { label: "Lao People's Democratic Republic +856", value: '856' },
    { label: 'Latvia +371', value: '371' },
    { label: 'Lebanon +961', value: '961' },
    { label: 'Lesotho +266', value: '266' },
    { label: 'Liberia +231', value: '231' },
    { label: 'Libyan Arab Jamahiriya +218', value: '218' },
    { label: 'Liechtenstein +423', value: '423' },
    { label: 'Lithuania +370', value: '370' },
    { label: 'Luxembourg +352', value: '352' },
    { label: 'Macao +853', value: '853' },
    { label: 'Macedonia, the Former Yugoslav Republic of +389', value: '389' },
    { label: 'Madagascar +261', value: '261' },
    { label: 'Malawi +265', value: '265' },
    { label: 'Malaysia +60', value: '60' },
    { label: 'Maldives +960', value: '960' },
    { label: 'Mali +223', value: '223' },
    { label: 'Malta +356', value: '356' },
    { label: 'Marshall Islands +692', value: '692' },
    { label: 'Martinique +596', value: '596' },
    { label: 'Mauritania +222', value: '222' },
    { label: 'Mauritius +230', value: '230' },
    { label: 'Mayotte +262', value: '262' },
    { label: 'Mexico +52', value: '52' },
    { label: 'Micronesia, Federated States of +691', value: '691' },
    { label: 'Moldova, Republic of +373', value: '373' },
    { label: 'Monaco +377', value: '377' },
    { label: 'Mongolia +976', value: '976' },
    { label: 'Montenegro +382', value: '382' },
    { label: 'Montserrat +1664', value: '1664' },
    { label: 'Morocco +212', value: '212' },
    { label: 'Mozambique +258', value: '258' },
    { label: 'Myanmar +95', value: '95' },
    { label: 'Namibia +264', value: '264' },
    { label: 'Nauru +674', value: '674' },
    { label: 'Nepal +977', value: '977' },
    { label: 'Netherlands +31', value: '31' },
    { label: 'Netherlands Antilles +599', value: '599' },
    { label: 'New Caledonia +687', value: '687' },
    { label: 'New Zealand +64', value: '64' },
    { label: 'Nicaragua +505', value: '505' },
    { label: 'Niger +227', value: '227' },
    { label: 'Nigeria +234', value: '234' },
    { label: 'Niue +683', value: '683' },
    { label: 'Norfolk Island +672', value: '672' },
    { label: 'Northern Mariana Islands +1670', value: '1670' },
    { label: 'Norway +47', value: '47' },
    { label: 'Oman +968', value: '968' },
    { label: 'Pakistan +92', value: '92' },
    { label: 'Palau +680', value: '680' },
    { label: 'Palestinian Territory, Occupied +970', value: '970' },
    { label: 'Panama +507', value: '507' },
    { label: 'Papua New Guinea +675', value: '675' },
    { label: 'Paraguay +595', value: '595' },
    { label: 'Peru +51', value: '51' },
    { label: 'Philippines +63', value: '63' },
    { label: 'Pitcairn +64', value: '64' },
    { label: 'Poland +48', value: '48' },
    { label: 'Portugal +351', value: '351' },
    { label: 'Puerto Rico +1787', value: '1787' },
    { label: 'Qatar +974', value: '974' },
    { label: 'Reunion +262', value: '262' },
    { label: 'Romania +40', value: '40' },
    { label: 'Russian Federation +7', value: '7' },
    { label: 'Rwanda +250', value: '250' },
    { label: 'Saint Barthelemy +590', value: '590' },
    { label: 'Saint Helena +290', value: '290' },
    { label: 'Saint Kitts and Nevis +1869', value: '1869' },
    { label: 'Saint Lucia +1758', value: '1758' },
    { label: 'Saint Martin +590', value: '590' },
    { label: 'Saint Pierre and Miquelon +508', value: '508' },
    { label: 'Saint Vincent and the Grenadines +1784', value: '1784' },
    { label: 'Samoa +684', value: '684' },
    { label: 'San Marino +378', value: '378' },
    { label: 'Sao Tome and Principe +239', value: '239' },
    { label: 'Saudi Arabia +966', value: '966' },
    { label: 'Senegal +221', value: '221' },
    { label: 'Serbia +381', value: '381' },
    { label: 'Serbia and Montenegro +381', value: '381' },
    { label: 'Seychelles +248', value: '248' },
    { label: 'Sierra Leone +232', value: '232' },
    { label: 'Singapore +65', value: '65' },
    { label: 'Sint Maarten +721', value: '721' },
    { label: 'Slovakia +421', value: '421' },
    { label: 'Slovenia +386', value: '386' },
    { label: 'Solomon Islands +677', value: '677' },
    { label: 'Somalia +252', value: '252' },
    { label: 'South Africa +27', value: '27' },
    {
        label: 'South Georgia and the South Sandwich Islands +500',
        value: '500'
    },
    { label: 'South Sudan +211', value: '211' },
    { label: 'Spain +34', value: '34' },
    { label: 'Sri Lanka +94', value: '94' },
    { label: 'Sudan +249', value: '249' },
    { label: 'Suriname +597', value: '597' },
    { label: 'Svalbard and Jan Mayen +47', value: '47' },
    { label: 'Swaziland +268', value: '268' },
    { label: 'Sweden +46', value: '46' },
    { label: 'Switzerland +41', value: '41' },
    { label: 'Syrian Arab Republic +963', value: '963' },
    { label: 'Taiwan +886', value: '886' },
    { label: 'Tajikistan +992', value: '992' },
    { label: 'Tanzania, United Republic of +255', value: '255' },
    { label: 'Thailand +66', value: '66' },
    { label: 'Timor-Leste +670', value: '670' },
    { label: 'Togo +228', value: '228' },
    { label: 'Tokelau +690', value: '690' },
    { label: 'Tonga +676', value: '676' },
    { label: 'Trinidad and Tobago +1868', value: '1868' },
    { label: 'Tunisia +216', value: '216' },
    { label: 'Turkey +90', value: '90' },
    { label: 'Turkmenistan +993', value: '993' },
    { label: 'Turks and Caicos Islands +1649', value: '1649' },
    { label: 'Tuvalu +688', value: '688' },
    { label: 'Uganda +256', value: '256' },
    { label: 'Ukraine +380', value: '380' },
    { label: 'United Arab Emirates +971', value: '971' },
    { label: 'United Kingdom +44', value: '44' },
    { label: 'United States +1', value: '1' },
    { label: 'Uruguay +598', value: '598' },
    { label: 'Uzbekistan +998', value: '998' },
    { label: 'Vanuatu +678', value: '678' },
    { label: 'Venezuela +58', value: '58' },
    { label: 'Vietnam +84', value: '84' },
    { label: 'Virgin Islands, British +1284', value: '1284' },
    { label: 'Virgin Islands, U.S. +1340', value: '1340' },
    { label: 'Wallis and Futuna +681', value: '681' },
    { label: 'Western Sahara +212', value: '212' },
    { label: 'Yemen +967', value: '967' },
    { label: 'Zambia +260', value: '260' },
    { label: 'Zimbabwe +263', value: '263' }
];
