import React, { useEffect } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IinitialState } from '../../reducers';
import { Banner } from '../common/Banner';
import {
    Ioption,
    IproductInfo,
    IreportTemplateChapter,
    Itile
} from '../../models';
import { IreportTemplate } from '../../models';
import { constants } from '../../constants/constants';
import { RouteComponentProps } from 'react-router-dom';
import ManageReportTemplateGrid from './ManageReportTemplateGrid';
import { Button } from 'react-bootstrap';
import ManageReportTemplateModal from './modals/ManageReportTemplateModal';
import ManageReportFilter from './ManageReportFilter';
import ManageReportAddChapterModal from './modals/ManageReportAddChapterModal';
import { getAllReportTemplates } from '../../actions/manageReportActions';
import { getProductInfo } from '../../actions/manageInventoryActions'; // import getProductInfo action

type Iprops = RouteComponentProps<any> & IdispatchProps & WithTranslation;

interface IdispatchProps {
    productInfo: IproductInfo;
    getProductInfo: typeof getProductInfo; // add getProductInfo to props
}

const ManageReportBuilder: React.FC<Iprops> = props => {
    const { t, productInfo } = props;
    const currentTile: Itile = constants.getTileByURL(props.location.pathname);

    const [reportTemplates, setReportTemplates] = React.useState<
        IreportTemplate[]
    >([]);

    // Function to fetch templates
    const fetchTemplates = async () => {
        const fetchedTemplates = await getAllReportTemplates();
        setReportTemplates(fetchedTemplates);
    };

    // Fetch product info on mount
    useEffect(() => {
        props.getProductInfo(); // Fetch product info when the component mounts
        fetchTemplates();
    }, []);

    const [selectedTemplate, setSelectedTemplate] = React.useState<
        IreportTemplate | undefined
    >(undefined);
    const [
        showManageTemplateModal,
        setShowManageTemplateModal
    ] = React.useState<boolean>(false);
    const [isAddingTemplate, setIsAddingTemplate] = React.useState<boolean>(
        false
    );
    const [showAddChapterModal, setShowAddChapterModal] = React.useState<
        boolean
    >(false);
    const [isAddingChapter, setIsAddingChapter] = React.useState<boolean>(
        false
    );
    const [selectedChapter, setSelectedChapter] = React.useState<
        IreportTemplateChapter | undefined
    >(undefined);

    // Filter values state
    const [filterValues, setFilterValues] = React.useState<{
        brand: string | null;
        jobType: string | null;
        default: boolean | null;
    }>({
        brand: null,
        jobType: null,
        default: null
    });

    // Filter change handler
    const handleFilterChange = (formValues: {
        brand?: Ioption | null;
        jobType?: Ioption | null;
        default?: Ioption | null;
    }) => {
        let convertedDefault: boolean | null = null;
        if (
            formValues.default?.value !== null &&
            formValues.default?.value !== undefined
        ) {
            convertedDefault = formValues.default.value as any;
        }

        setFilterValues({
            brand: formValues.brand?.value || null,
            jobType: formValues.jobType?.value || null,
            default: convertedDefault // this will technically only be a boolean or null, even though Ioption.value is defined as a string...
        });
    };

    // Handle row click event to open modal
    const handleRowClick = (selectedTemplate: IreportTemplate) => {
        setSelectedTemplate(selectedTemplate);
        setIsAddingTemplate(false);
        setShowManageTemplateModal(true);
    };

    // Open add template modal
    const handleAddTemplateClick = () => {
        setSelectedTemplate(undefined);
        setIsAddingTemplate(true);
        setShowManageTemplateModal(true);
    };

    // Open add or edit chapter modal
    const openAddChapterModal = (
        isAdd: boolean,
        chapter?: IreportTemplateChapter
    ) => {
        setIsAddingChapter(isAdd); // Track if it's "Add" or "Edit" for chapter modal
        setSelectedChapter(chapter); // Track the selected chapter if editing
        setShowAddChapterModal(true);
    };

    return (
        <div className="manage-report-builder">
            <Banner
                title={t('Heading')}
                img={currentTile.srcBanner}
                color={currentTile.color}
            />

            <ManageReportFilter
                productInfo={productInfo}
                setFormGroup={handleFilterChange} // Update filter values in parent state
            />

            <div style={{ width: '100%', padding: '10px' }}>
                <ManageReportTemplateGrid
                    rowClickHandler={handleRowClick}
                    filterValues={filterValues} // Pass filterValues to the grid
                    tableData={reportTemplates}
                />
                <Button
                    className="table-add-button"
                    bsStyle="link"
                    onClick={handleAddTemplateClick}
                >
                    {t('AddTemplate')}
                </Button>
            </div>

            <ManageReportTemplateModal
                title={
                    isAddingTemplate
                        ? t('IsAddTemplateTitle')
                        : t('IsEditTemplateTitle')
                }
                show={showManageTemplateModal}
                onHide={() => setShowManageTemplateModal(false)}
                className="first-modal"
                closeModal={() => setShowManageTemplateModal(false)}
                template={selectedTemplate}
                openAddChapter={(isAdd, chapter) =>
                    openAddChapterModal(isAdd, chapter)
                } // Pass isAdd and chapter to track add/edit for chapters
                isAdd={isAddingTemplate}
                updateTemplate={(template: IreportTemplate) =>
                    setSelectedTemplate(template)
                }
                refetchTemplates={fetchTemplates}
            />

            <ManageReportAddChapterModal
                show={showAddChapterModal}
                onHide={() => setShowAddChapterModal(false)}
                title={
                    isAddingChapter
                        ? t('IsAddChapterTitle')
                        : t('IsEditChapterTitle')
                } // Dynamic title based on isAddingChapter
                className="second-modal"
                closeModal={() => setShowAddChapterModal(false)}
                template={selectedTemplate}
                selectedChapter={selectedChapter} // Pass the selected chapter
                isAddingChapter={isAddingChapter} // Pass if it's adding or editing
                updateTemplate={(template: IreportTemplate) =>
                    setSelectedTemplate(template)
                }
            />
        </div>
    );
};

const mapStateToProps = (state: IinitialState) => {
    return { productInfo: state.productInfo };
};

export default withTranslation('manageReportBuilder')(
    connect(mapStateToProps, { getProductInfo })(ManageReportBuilder)
);
