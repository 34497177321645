import React, { useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { IinitialState } from '../../../reducers';
import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    FormGroup
} from 'react-reactive-form';
import { FormUtil } from '../../common/FormUtil';
import { WithCommonModal } from '../../common/WithCommonModal';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import {
    Ioption,
    IproductInfo,
    IreportTemplate,
    IreportTemplateChapter
} from '../../../models';
import { jobTypeOptions } from '../../../constants/constants';
import {
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    getAllReportTemplateChapters,
    createReportTemplate,
    updateReportTemplate,
    deleteReportTemplate,
    updateReportTemplateChapter
} from '../../../actions/manageReportActions';
import { toastr } from 'react-redux-toastr'; // For confirmation dialogs
import { beginAjaxCall, endAjaxCall } from '../../../actions/ajaxStatusActions';

interface Iprops {
    closeModal: () => void;
    template: IreportTemplate | undefined;
    openAddChapter: (isAdd: boolean, chapter?: IreportTemplateChapter) => void; // Pass flag to differentiate add/edit and optional chapter
    isAdd: boolean;
    templateId?: string;
    updateTemplate: (template: IreportTemplate) => void;
    refetchTemplates: () => void;
}

interface IdispatchProps {
    productInfo: IproductInfo;
}

const ManageReportTemplateModal: React.FC<Iprops &
    IdispatchProps &
    WithTranslation> = props => {
    const { t, productInfo, template } = props;
    const disabled = false;
    const formGroupRef = React.useRef<FormGroup | null>(null);
    const dispatch = useDispatch();

    const fieldConfig = useMemo((): FieldConfig => {
        let selectedBrand: Ioption | undefined = undefined;
        let selectedJobType: Ioption | undefined = undefined;
        let selectedDefault = false;
        let templateName = ''; // Variable for template name

        const translatedJobTypeOptions = jobTypeOptions.map(option => ({
            ...option,
            label: t(`nsJob:${option.label}`)
        }));

        if (template) {
            selectedBrand = productInfo.allBrandOptions.find(
                (option: Ioption) => option.value === template.brandID
            );

            selectedJobType = translatedJobTypeOptions.find(
                (option: Ioption) => option.value === template.jobTypeID
            );

            selectedDefault = template.isDefault;
            templateName = template.templateName; // Get template name if template exists
        }

        return {
            controls: {
                // Template Name field
                templateName: {
                    render: FormUtil.TextInput,
                    meta: {
                        label: t('FilterTemplateName'),
                        colWidth: 12,
                        placeholder: 'Enter template name',
                        name: 'templateName'
                    },
                    formState: {
                        value: templateName,
                        disabled // Disable if needed, based on the "disabled" variable
                    },
                    required: true
                },
                brand: {
                    render: FormUtil.Select,
                    meta: {
                        options: [
                            ...productInfo.allBrandOptions // Just the options, no placeholder inside
                        ],
                        label: t('FilterBrand'),
                        colWidth: 12,
                        placeholder: 'Choose a brand', // Placeholder text here
                        name: 'brand',
                        defaultValue: undefined,
                        isClearable: true
                    },
                    formState: {
                        value: selectedBrand,
                        disabled
                    },
                    required: true
                },
                jobType: {
                    render: FormUtil.Select,
                    meta: {
                        options: [
                            ...translatedJobTypeOptions // Just the options, no placeholder inside
                        ],
                        label: t('FilterJobType'),
                        colWidth: 12,
                        placeholder: 'Choose a job type', // Placeholder text here
                        name: 'jobType',
                        defaultValue: undefined,
                        isClearable: true
                    },
                    formState: {
                        value: selectedJobType,
                        disabled
                    },
                    required: true
                },
                default: {
                    render: (props: any) => (
                        <div className="form-toggle-wrapper d-flex align-items-center">
                            <FormUtil.Toggle {...props} />
                        </div>
                    ),
                    meta: {
                        label: t('FilterDefault'),
                        colWidth: 12,
                        name: 'default',
                        defaultValue: false // Set the default value
                    },
                    formState: {
                        value: selectedDefault,
                        disabled
                    }
                }
            }
        };
    }, [template, productInfo, jobTypeOptions]);

    const [loading, setLoading] = React.useState<boolean>(true);

    const setForm = (form: AbstractControl) => {
        formGroupRef.current = form as FormGroup;
    };

    const fetchChapters = async () => {
        try {
            // Ensure template exists before trying to access templateId
            if (!template || !template.id) {
                console.info('Template is undefined or missing templateID.'); // technically not an error if we are creating a new template
                return;
            }

            const data = await getAllReportTemplateChapters(template.id);
            props.updateTemplate({ ...template, reportTemplateChapters: data }); // Update the template with fetched chapters
        } catch (error) {
            console.error('No Chapters Available for this Template');
        } finally {
            setLoading(false);
        }
    };

    // Handle the reordering of chapters
    const onSortEndHandler = async ({
        oldIndex,
        newIndex
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (template?.reportTemplateChapters) {
            const newItems = arrayMove(
                template?.reportTemplateChapters,
                oldIndex,
                newIndex
            );
            const updatedChapters = newItems.map((item, index) => ({
                ...item,
                order: index + 1
            }));

            try {
                if (template?.id) {
                    dispatch(beginAjaxCall());

                    await updateReportTemplateChapter(updatedChapters)
                        .then(async () => {
                            const data = await getAllReportTemplateChapters(
                                template?.id || ''
                            );

                            props.updateTemplate({
                                ...template,
                                reportTemplateChapters: data
                            }); // Update the template with fetched chapters
                        })
                        .finally(() => {
                            dispatch(endAjaxCall());
                        });
                }
            } catch (error) {
                console.error('Failed to update chapter order:', error);
            }
        }
    };

    // Drag handle icon component
    const DragHandle = SortableHandle(() => (
        <span>
            <FontAwesomeIcon icon={['far', 'bars']} size="lg" />
        </span>
    ));

    // Open add or edit chapter modal
    const handleChapterClick = (chapter: IreportTemplateChapter) => {
        // Open the Edit Chapter modal by passing the chapter object and set isAdd to false
        props.openAddChapter(false, chapter); // Editing mode
    };

    // Sortable item component, displaying ChapterName only
    const SortableItem = SortableElement(
        ({ item }: { item: IreportTemplateChapter }) => (
            <ListGroupItem onClick={() => handleChapterClick(item)}>
                <Row className="vertical-align">
                    <Col xs={1}>
                        <DragHandle />
                    </Col>
                    <Col xs={11}>
                        <h5 className="list-label">
                            {`${item?.reportChapter?.chapterName} - ${item?.reportChapter?.reportRevision}`}
                        </h5>
                    </Col>
                </Row>
            </ListGroupItem>
        )
    );

    // Sortable list of chapters
    const SortableList = SortableContainer(
        ({ items }: { items: IreportTemplateChapter[] }) => {
            if (items && items.length > 0 && Array.isArray(items)) {
                return (
                    <ListGroup>
                        {items.map((value, index) => (
                            <SortableItem
                                key={`item-${index}`}
                                index={index}
                                item={value}
                            />
                        ))}
                    </ListGroup>
                );
            } else {
                return null;
            }
        }
    );

    React.useEffect(() => {
        fetchChapters(); // Call the fetch function on component mount
    }, []);

    // Open add chapter modal
    const handleAddChapterClick = () => {
        // Call the openAddChapter function to open the add chapter modal, pass `true` for "Add Chapter"
        props.openAddChapter(true); // Adding mode
    };

    const handleDelete = () => {
        if (!template?.id) {
            console.error('Template ID is missing');
            return;
        }

        toastr.confirm('Are you sure you want to delete this template?', {
            onOk: async () => {
                try {
                    await deleteReportTemplate(template.id as string); // Safely cast to string here
                    props.refetchTemplates(); // Refresh the grid after deletion
                    props.closeModal(); // Close modal after deletion
                } catch (error) {
                    console.error('Failed to delete report template:', error);
                }
            },
            onCancel: () => {
                console.log('Deletion canceled');
            }
        });
    };

    const handleFormSubmit = async () => {
        if (!formGroupRef.current) {
            console.error('Form group is not initialized');
            return;
        }

        const formValues = formGroupRef.current.value;

        // Validation checks for required fields
        if (!formValues.templateName || formValues.templateName.trim() === '') {
            toastr.error('Error', 'Template name cannot be empty.');
            return; // Stop execution if validation fails
        }
        if (!formValues.brand) {
            toastr.error('Error', 'Please select a brand.');
            return; // Stop execution if validation fails
        }
        if (!formValues.jobType) {
            toastr.error('Error', 'Please select a job type.');
            return; // Stop execution if validation fails
        }

        const formData: IreportTemplate = {
            templateName: formValues.templateName,
            jobTypeID: formValues.jobType ? formValues.jobType.value : '',
            brandID: formValues.brand ? formValues.brand.value : '',
            isDefault: formValues.default || false,
            reportTemplateChapters: template?.reportTemplateChapters || [] // Include chapters if applicable
        };

        try {
            if (props.isAdd === false && template?.id) {
                // Editing mode
                const response = await updateReportTemplate(
                    template.id,
                    formData
                );

                if (response) {
                    props.refetchTemplates();
                    props.closeModal();
                }
            } else {
                // Adding mode
                const response = await createReportTemplate(formData);

                if (response) {
                    props.refetchTemplates();
                    props.closeModal();
                }
            }
        } catch (error) {
            console.error('Failed to save report template:', error);
            toastr.error('Error', 'Failed to save report template.');
        }
    };

    return (
        <div className="manage-report-template-modal">
            <div className="manage-template-form-container">
                <FormGenerator onMount={setForm} fieldConfig={fieldConfig} />
            </div>

            <Col xs={12} className="form-buttons text-right">
                <Button
                    bsStyle="default"
                    type="button"
                    className="pull-left"
                    onClick={props.closeModal}
                >
                    {t('cancel')}
                </Button>

                {!props.isAdd && (
                    <Button
                        type="button"
                        bsStyle="warning"
                        className="ml-2 deleteButton"
                        onClick={handleDelete}
                    >
                        {t('Delete')}
                    </Button>
                )}

                <Button
                    bsStyle="primary"
                    type="submit"
                    onClick={handleFormSubmit}
                >
                    Save
                </Button>
            </Col>

            {!props.isAdd && (
                <Button bsStyle="link" onClick={handleAddChapterClick}>
                    {t('manageReports:Add Chapter')}
                </Button>
            )}

            <div className="chapter-list-container">
                {loading ? (
                    <div>Loading...</div> // Loading state while fetching data
                ) : template?.reportTemplateChapters.length === 0 ? ( // Check if chapters are empty
                    <div></div> // Message when no chapters
                ) : (
                    <SortableList
                        items={template?.reportTemplateChapters || []}
                        onSortEnd={onSortEndHandler}
                        useDragHandle
                    />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        productInfo: state.productInfo
    };
};

export default withTranslation('manageReportBuilder')(
    connect(mapStateToProps, {})(WithCommonModal(ManageReportTemplateModal))
);
