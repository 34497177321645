export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const END_AJAX_CALL = 'END_AJAX_CALL';
export const _SUCCESS = '_SUCCESS';
export const _FAILED = '_FAILED';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';
export const USER_LOGOUT_SESSION_ONLY_SUCCESS =
    'USER_LOGOUT_SESSION_ONLY_SUCCESS';

export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED';

export const USER_QUEUE_SUCCESS = 'USER_QUEUE_SUCCESS';
export const USER_QUEUE_FAILED = 'USER_QUEUE_FAILED';

export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER';
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
export const USER_SAVE_FAILED = 'USER_SAVE_FAILED';

export const AAD_LOGIN_ = 'AAD_LOGIN';
export const AAD_LOGOUT_SUCCESS = 'AAD_LOGOUT_SUCCESS';
export const AAD_LOGIN = 'AAD_LOGIN';
export const AAD_LOGOUT_FAILED = 'AAD_LOGOUT_FAILED';

export const SET_REDIRECT_REFERRER = 'SET_REDIRECT_REFERRER';
export const REMOVE_REDIRECT_REFERRER = 'REMOVE_REDIRECT_REFERRER';

export const SET_REDIRECT_PATHNAME = 'SET_REDIRECT_PATHNAME';
export const REMOVE_REDIRECT_PATHNAME = 'REMOVE_REDIRECT_PATHNAME';

export const USER_APPROVE_SUCCESS = 'USER_APPROVE_SUCCESS';
export const USER_APPROVE_FAILED = 'USER_APPROVE_FAILED';

export const USER_REJECT_SUCCESS = 'USER_APPROVE_SUCCESS';
export const USER_REJECT_FAILED = 'USER_APPROVE_FAILED';

export const USER_QUEUE_SAVE_SUCCESS = 'USER_QUEUE_SAVE_SUCCESS';
export const USER_QUEUE_SAVE_FAILED = 'USER_QUEUE_SAVE_FAILED';

export const USER_QUEUE_UPDATE_SELECTED = 'USER_QUEUE_UPDATE_SELECTED';

export const SET_SELECTED_MANAGE_USER = 'SET_SELECTED_MANAGE_USER';

export const USER_UPDATE = 'USER_UPDATE';

export const USER_UPDATE_POSTAL_CODES_SUCCESS =
    'USER_UPDATE_POSTAL_CODES_SUCCESS';
export const USER_UPDATE_POSTAL_CODES_FAILED =
    'USER_UPDATE_POSTAL_CODES_FAILED';

export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAILED = 'USER_UPDATE_PROFILE_FAILED';

export const USER_QUEUE_INCREMENT = 'USER_QUEUE_INCREMENT';
export const USER_QUEUE_DECREMENT = 'USER_QUEUE_DECREMENT';
export const SET_TABLE_FILTER_MANAGE_USER_QUEUE =
    'SET_TABLE_FILTER_MANAGE_USER_QUEUE';

export const SET_FORM_VALUES_MANAGE_USER = 'SET_FORM_VALUES_MANAGE_USER';
export const SET_FORM_VALUES_MANAGE_USER_QUEUE =
    'SET_FORM_VALUES_MANAGE_USER_QUEUE';
export const UPDATE_FORM_VALUES_MANAGE_USER_QUEUE =
    'UPDATE_FORM_VALUES_MANAGE_USER_QUEUE';
export const UPDATE_FORM_VALUES_MANAGE_USER = 'UPDATE_FORM_VALUES_MANAGE_USER';

export const USER_QUEUE_TOTAL_PAGES = 'USER_QUEUE_TOTAL_PAGES';
export const SET_SELECTED_MANAGE_USER_QUEUE = 'SET_SELECTED_MANAGE_USER_QUEUE';

export const GET_SECURITY_SUCCESS = 'GET_SECURITY_SUCCESS';
export const GET_SECURITY_FAILED = 'GET_SECURITY_FAILED';

export const GET_FACILITIES_SUCCESS = 'GET_FACILITIES_SUCCESS';
export const MERGE_FACILITIES_SUCCESS = 'MERGE_FACILITIES_SUCCESS';
export const GET_FACILITIES_FAILED = 'GET_FACILITIES_FAILED';

export const FACILITY_UPDATE_SUCCESS = 'FACILITY_UPDATE_SUCCESS';
export const FACILITY_UPDATE_FAILED = 'FACILITY_UPDATE_FAILED';

export const ADD_JOB_FACILITY_SUCCESS = 'ADD_JOB_FACILITY_SUCCESS';
export const ADD_JOB_FACILITY_FAILED = 'ADD_JOB_FACILITY_FAILED';

export const FACILITY_DELETE_SUCCESS = 'FACILITY_DELETE_SUCCESS';
export const FACILITY_DELETE_FAILED = 'FACILITY_DELETE_FAILED';

export const TOGGLE_MODAL_FACILITY_CONTRACT = 'TOGGLE_MODAL_FACILITY_CONTRACT';
export const UPDATE_FACILITY_CONTRACT = 'UPDATE_FACILITY_CONTRACT';
export const UPDATE_FORM_VALUES_MANAGE_FACILITY_CONTRACT =
    'UPDATE_FORM_VALUES_MANAGE_FACILITY_CONTRACT';
export const SET_FORM_VALUES_MANAGE_FACILITY_CONTRACT =
    'SET_FORM_VALUES_MANAGE_FACILITY_CONTRACT';

// CONTACT CONSTANTS
export const TOGGLE_MODAL_FACILITY_CONTACT = 'TOGGLE_MODAL_FACILITY_CONTACT';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_FAILED = 'ADD_CONTACT_FAILED';
export const GET_CONTACTS_BY_FACILITY_SUCCESS =
    'GET_CONTACTS_BY_FACILITY_SUCCESS';
export const GET_CONTACTS_BY_FACILITY_FAILED =
    'GET_CONTACTS_BY_FACILITY_FAILED';
export const FIND_USERS_OR_CONTACT_BY_EMAIL_FAILED =
    'FIND_USERS_OR_CONTACT_BY_EMAIL_FAILED';
export const FIND_USERS_OR_CONTACT_BY_EMAIL_SUCCESS =
    'FIND_USERS_OR_CONTACT_BY_EMAIL_SUCCESS';
export const GET_USERS_MATCHED_EMAIL_FAILED = 'GET_USERS_MATCHED_EMAIL_FAILED';
export const DELETE_CONTACT_FACILITY_SUCCESS =
    'DELETE_CONTACT_FACILITY_SUCCESS';
export const DELETE_CONTACT_FACILITY_FAILED = 'DELETE_CONTACT_FACILITY_FAILED';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_FAILED = 'UPDATE_CONTACT_FAILED';
export const UPDATE_SELECTED_CONTACT = 'UPDATE_SELECTED_CONTACT';
export const SET_SELECTED_CONTACT_ID = 'SET_SELECTED_CONTACT_ID';
export const CLEAR_SELECTED_CONTACT_ID = 'CLEAR_SELECTED_CONTACT_ID';
export const CLEAR_USERS_MATCHED_EMAIL = 'CLEAR_USERS_MATCHED_EMAIL';

export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';
export const TOGGLE_MODAL_EDIT_FACILITY = 'TOGGLE_MODAL_EDIT_FACILITY';
export const TOGGLE_MODAL_EDIT_PROFILE = 'TOGGLE_MODAL_EDIT_PROFILE';
export const TOGGLE_MODAL_SECURITY_FUNCTIONS =
    'TOGGLE_MODAL_SECURITY_FUNCTIONS';
export const TOGGLE_MODAL_SIDE_MENU = 'TOGGLE_MODAL_SIDE_MENU';

export const USER_MANAGE_SUCCESS = 'USER_MANAGE_SUCCESS';
export const USER_MANAGE_FAILED = 'USER_MANAGE_FAILED';
export const USER_MANAGE_INCREMENT = 'USER_MANAGE_INCREMENT';
export const USER_MANAGE_DECREMENT = 'USER_MANAGE_DECREMENT';
export const USER_MANAGE_TOTAL_PAGES = 'USER_MANAGE_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_USER = 'SET_TABLE_FILTER_MANAGE_USER';
export const UPDATE_USER_LOCAL_COUNTRY = 'UPDATE_USER_LOCAL_COUNTRY';

// TEAM
export const TEAM_MANAGE_SUCCESS = 'TEAM_MANAGE_SUCCESS';
export const TEAM_MANAGE_FAILED = 'TEAM_MANAGE_FAILED';
export const TEAM_MANAGE_INCREMENT = 'TEAM_MANAGE_INCREMENT';
export const TEAM_MANAGE_DECREMENT = 'TEAM_MANAGE_DECREMENT';
export const TEAM_MANAGE_TOTAL_PAGES = 'TEAM_MANAGE_TOTAL_PAGES';
export const TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS';
export const TEAM_UPDATE_FAILED = 'TEAM_UPDATE_FAILED';
export const TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS';
export const TEAM_DELETE_FAILED = 'TEAM_DELETE_FAILED';
export const TEAM_SAVE_SUCCESS = 'TEAM_SAVE_SUCCESS';
export const TEAM_SAVE_FAILED = 'TEAM_SAVE_FAILED';
export const TOGGLE_MODAL_EDIT_TEAM = 'TOGGLE_MODAL_EDIT_TEAM';
export const SET_TABLE_FILTER_MANAGE_TEAM = 'SET_TABLE_FILTER_MANAGE_TEAM';
export const SET_FORM_VALUES_MANAGE_TEAM_MEMBER =
    'SET_FORM_VALUES_MANAGE_TEAM_MEMBER';
export const UPDATE_FORM_VALUES_MANAGE_TEAM_MEMBER =
    'UPDATE_FORM_VALUES_MANAGE_TEAM_MEMBER';
export const SET_SELECTED_MANAGE_TEAM = 'SET_SELECTED_MANAGE_TEAM';

// inventory
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_FAILED = 'GET_INVENTORY_FAILED';
export const INVENTORY_INCREMENT = 'INVENTORY_INCREMENT';
export const INVENTORY_DECREMENT = 'INVENTORY_DECREMENT';
export const INVENTORY_TOTAL_PAGES = 'INVENTORY_TOTAL_PAGES';
export const INSTALL_UPDATE_BULK = 'INSTALL_UPDATE_BULK';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAILED = 'PRODUCT_UPDATE_FAILED';
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS';
export const PRODUCT_ADD_FAILED = 'PRODUCT_ADD_FAILED';
export const PRODUCT_MERGE_SUCCESS = 'PRODUCT_MERGE_SUCCESS';
export const PRODUCT_MERGE_FAILED = 'PRODUCT_MERGE_FAILED';
export const TOGGLE_MODAL_EDIT_PRODUCT = 'TOGGLE_MODAL_EDIT_PRODUCT';
export const HIDE_MODAL_EDIT_PRODUCT = 'HIDE_MODAL_EDIT_PRODUCT';
export const GET_PRODUCT_INFO_SUCCESS = 'GET_PRODUCT_INFO_SUCCESS';
export const UPDATE_PRODUCT_INFO_SUCCESS = 'UPDATE_PRODUCT_INFO_SUCCESS';
export const GET_PRODUCT_INFO_FAILED = 'GET_PRODUCT_INFO_FAILED';
export const UPDATE_PRODUCT_INFO_BRANDS_SUCCESS =
    'UPDATE_PRODUCT_INFO_BRANDS_SUCCESS';
export const INSTALL_UPDATE_SUCCESS = 'INSTALL_UPDATE_SUCCESS';
export const INSTALL_UPDATE_FAILED = 'INSTALL_UPDATE_FAILED';
export const INSTALL_ADD_SUCCESS = 'INSTALL_ADD_SUCCESS';
export const INSTALL_ADD_FAILED = 'INSTALL_ADD_FAILED';
export const INSTALL_DELETE_SUCCESS = 'INSTALL_DELETE_SUCCESS';
export const INSTALL_DELETE_FAILED = 'INSTALL_DELETE_FAILED';
export const TOGGLE_MODAL_EDIT_INSTALL = 'TOGGLE_MODAL_EDIT_INSTALL';
export const TOGGLE_MODAL_INSTALL_CONTACT = 'TOGGLE_MODAL_INSTALL_CONTACT';
export const TOGGLE_MODAL_IMPORT_INSTALL = 'TOGGLE_MODAL_IMPORT_INSTALL';
export const IMPORT_INSTALL_SUCCESS = 'IMPORT_INSTALL_SUCCESS';
export const IMPORT_INSTALL_FAILED = 'IMPORT_INSTALL_FAILED';
export const SET_TABLE_FILTER_MANAGE_INVENTORY =
    'SET_TABLE_FILTER_MANAGE_INVENTORY';
export const SET_PRODUCT_TABLE_FILTER_MANAGE_INVENTORY =
    'SET_PRODUCT_TABLE_FILTER_MANAGE_INVENTORY';
export const INSTALL_CONTACT_SUCCESS = 'INSTALL_CONTACT_SUCCESS';
export const INSTALL_CONTACT_FAILED = 'INSTALL_CONTACT_FAILED';
export const TOGGLE_MODAL_SEARCH_NEW_PRODUCTS =
    'TOGGLE_MODAL_SEARCH_NEW_PRODUCTS';
export const HIDE_MODAL_SEARCH_NEW_PRODUCTS = 'HIDE_MODAL_SEARCH_NEW_PRODUCTS';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';
export const NEW_PRODUCTS_RESET = 'NEW_PRODUCTS_RESET';
export const TOGGLE_MODAL_MP_RESULT = 'TOGGLE_MODAL_MP_RESULT';
export const TOGGLE_MODAL_MP_RESULT_WORK_ORDER =
    'TOGGLE_MODAL_MP_RESULT_WORK_ORDER';
export const TOGGLE_MODAL_MP_RESULT_HISTORY = 'TOGGLE_MODAL_MP_RESULT_HISTORY';
export const TOGGLE_MODAL_MP_RESULT_NOTES = 'TOGGLE_MODAL_MP_RESULT_NOTES';
export const SET_INSTALL_BATCH_MODE = 'SET_INSTALL_BATCH_MODE';
export const SET_SELECTED_MANAGE_INVENTORY_PRODUCT_ID =
    'SET_SELECTED_MANAGE_INVENTORY_PRODUCT_ID';
export const CLEAR_SELECTED_MANAGE_INVENTORY_PRODUCT_ID =
    'CLEAR_SELECTED_MANAGE_INVENTORY_PRODUCT_ID';
export const SET_SELECTED_MANAGE_INVENTORY_INSTALL_BASE_ID =
    'SET_SELECTED_MANAGE_INVENTORY_INSTALL_BASE_ID';
export const CLEAR_SELECTED_MANAGE_INVENTORY_INSTALL_BASE_ID =
    'CLEAR_SELECTED_MANAGE_INVENTORY_INSTALL_BASE_ID';
export const SET_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH =
    'SET_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH';
export const UPDATE_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH =
    'UPDATE_FORM_VALUES_MANAGE_INVENTORY_PRODUCT_SEARCH';
export const UPDATE_FORM_VALUES_MANAGE_INVENTORY_INSTALL =
    'UPDATE_FORM_VALUES_MANAGE_INVENTORY_INSTALL';
export const SET_FORM_VALUES_MANAGE_INVENTORY_INSTALL =
    'SET_FORM_VALUES_MANAGE_INVENTORY_INSTALL';

// shopping cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';
export const DECREASE_FROM_CART = 'DECREASE_FROM_CART';
export const UPDATE_QUANTITY_CART = 'UPDATE_QUANTITY_CART';

export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_TRAINING_SUCCESS = 'CHECKOUT_TRAINING_SUCCESS';
export const CHECKOUT_TRAINING_FAILED = 'CHECKOUT_TRAINING_FAILED';
export const CHECKOUT_INVENTORY_SUCCESS = 'CHECKOUT_INVENTORY_SUCCESS';
export const CHECKOUT_INVENTORY_FAILED = 'CHECKOUT_INVENTORY_FAILED';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const TOGGLE_MODAL_SHOPPING_CART_TRAINING =
    'TOGGLE_MODAL_SHOPPING_CART_TRAINING';
export const TOGGLE_MODAL_SHOPPING_CART_INVENTORY =
    'TOGGLE_MODAL_SHOPPING_CART_INVENTORY';
export const GET_PURCHASED_TRAINING_SUCCESS = 'GET_PURCHASED_TRAINING_SUCCESS';
export const GET_PURCHASED_TRAINING_FAILED = 'GET_PURCHASED_TRAINING_FAILED';

// product queue
export const PRODUCT_QUEUE_SUCCESS = 'PRODUCT_QUEUE_SUCCESS';
export const PRODUCT_QUEUE_FAILED = 'PRODUCT_QUEUE_FAILED';

export const PRODUCT_APPROVE_SUCCESS = 'PRODUCT_APPROVE_SUCCESS';
export const PRODUCT_APPROVE_FAILED = 'PRODUCT_APPROVE_FAILED';

export const PRODUCT_REJECT_SUCCESS = 'PRODUCT_APPROVE_SUCCESS';
export const PRODUCT_REJECT_FAILED = 'PRODUCT_APPROVE_FAILED';

export const PRODUCT_QUEUE_TOTAL_PAGES = 'PRODUCT_QUEUE_TOTAL_PAGES';
export const TOGGLE_MODAL_APPROVE_PRODUCT = 'TOGGLE_MODAL_APPROVE_PRODUCT';
export const SET_TABLE_FILTER_MANAGE_PRODUCT_QUEUE =
    'SET_TABLE_FILTER_MANAGE_PRODUCT_QUEUE';
export const PRODUCT_QUEUE_PRODUCT_UPDATE_SUCCESS =
    'PRODUCT_QUEUE_PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_QUEUE_PRODUCT_UPDATE_FAILED =
    'PRODUCT_QUEUE_PRODUCT_UPDATE_FAILED';
export const MERGEABLE_PRODUCTS_SUCCESS = 'MERGEABLE_PRODUCTS_SUCCESS';
export const MERGEABLE_PRODUCTS_FAILED = 'MERGEABLE_PRODUCTS_FAILED';

// manage products
export const GET_MANAGE_PRODUCT_SUCCESS = 'GET_MANAGE_PRODUCT_SUCCESS';
export const GET_MANAGE_PRODUCT_FAILED = 'GET_MANAGE_PRODUCT_FAILED';
export const SET_TABLE_FILTER_MANAGE_PRODUCT =
    'SET_TABLE_FILTER_MANAGE_PRODUCT';
export const PRODUCT_MANAGE_TOTAL_PAGES = 'PRODUCT_MANAGE_TOTAL_PAGES';

// manage jobs
export const JOB_MANAGE_SUCCESS = 'JOB_MANAGE_SUCCESS';
export const JOB_MANAGE_FAILED = 'JOB_MANAGE_FAILED';
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS';
export const JOB_UPDATE_FAILED = 'JOB_UPDATE_FAILED';
export const JOB_SAVE_SUCCESS = 'JOB_SAVE_SUCCESS';
export const JOB_SAVE_FAILED = 'JOB_SAVE_FAILED';
export const JOB_GET_SUCCESS = 'JOB_GET_SUCCESS';
export const JOB_GET_FAILED = 'JOB_GET_FAILED';
export const JOB_DELETE_SUCCESS = 'JOB_DELETE_SUCCESS';
export const JOB_DELETE_FAILED = 'JOB_DELETE_FAILED';
export const GET_FSE_SUCCESS = 'GET_FSE_SUCCESS';
export const GET_FSE_FAILED = 'GET_FSE_FAILED';
export const JOB_MANAGE_TOTAL_PAGES = 'JOB_MANAGE_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_JOB = 'SET_TABLE_FILTER_MANAGE_JOB';
export const SET_FORM_VALUES_MANAGE_JOB = 'SET_FORM_VALUES_MANAGE_JOB';
export const UPDATE_FORM_VALUES_MANAGE_JOB = 'UPDATE_FORM_VALUES_MANAGE_JOB';
export const JOBS_BY_FACILITY_SUCCESS = 'JOBS_BY_FACILITY_SUCCESS';
export const JOBS_BY_FACILITY_FAILED = 'JOBS_BY_FACILITY_FAILED';
export const JOBS_REPAIR_MAINTENANCE_SUCCESS =
    'JOBS_REPAIR_MAINTENANCE_SUCCESS';
export const JOBS_REPAIR_MAINTENANCE_FAILED = 'JOBS_REPAIR_MAINTENANCE_FAILED';
export const JOB_UPDATE_WORKORDERS_SUCCESS = 'JOB_UPDATE_WORKORDERS_SUCCESS';
export const JOB_UPDATE_WORKORDERS_FAILED = 'JOB_UPDATE_WORKORDERS_FAILED';
export const UPDATE_SELECTED_JOB = 'UPDATE_SELECTED_JOB';
export const SET_SELECTED_JOB = 'SET_SELECTED_JOB';

// manage leads
export const LEADS_MANAGE_SUCCESS = 'LEADS_MANAGE_SUCCESS';
export const LEADS_MANAGE_FAILED = 'LEADS_MANAGE_FAILED';
export const LEAD_ACTIVITIES_MANAGE_SUCCESS = 'LEAD_ACTIVITIES_MANAGE_SUCCESS';
export const LEAD_ACTIVITIES_MANAGE_FAILED = 'LEAD_ACTIVITIES_MANAGE_FAILED';
export const SET_TABLE_FILTER_MANAGE_LEADS = 'SET_TABLE_FILTER_MANAGE_LEADS';
export const LEADS_MANAGE_TOTAL_PAGES = 'LEADS_MANAGE_TOTAL_PAGES';
export const LEAD_INSTALL_DELETE_SUCCESS = 'LEAD_INSTALL_DELETE_SUCCESS';
export const LEAD_INSTALL_UPDATE_SUCCESS = 'LEAD_INSTALL_UPDATE_SUCCESS';
export const LEAD_INSTALL_DELETE_FAILED = 'LEAD_INSTALL_DELETE_FAILED';
export const SET_SELECTED_LEAD = 'SET_SELECTED_LEAD';
export const UPDATE_SELECTED_LEAD = 'UPDATE_SELECTED_LEAD';
export const LEAD_UPDATE_SUCCESS = 'LEAD_UPDATE_SUCCESS';
export const LEAD_UPDATE_FAILED = 'LEAD_UPDATE_FAILED';
export const LEADS_BULK_UPDATE_STATUS_SUCCESS =
    'LEADS_BULK_UPDATE_STATUS_SUCCESS';
export const LEADS_BULK_UPDATE_STATUS_FAILED =
    'LEADS_BULK_UPDATE_STATUS_FAILED';
export const SELECTED_LEAD_ACTIVITIES = 'SELECTED_LEAD_ACTIVITIES';
export const TOGGLE_MODAL_LEAD_ACTIVITIES = 'TOGGLE_MODAL_LEAD_ACTIVITIES';
export const TOGGLE_MODAL_EDIT_LEAD_ACTIVITY =
    'TOGGLE_MODAL_EDIT_LEAD_ACTIVITY';
export const UPDATE_SELECTED_LEAD_ACTIVITY = 'UPDATE_SELECTED_LEAD_ACTIVITY';
export const SET_SELECTED_LEAD_ACTIVITY = 'SET_SELECTED_LEAD_ACTIVITY';
export const LEAD_ACTIVITY_DELETE_SUCCESS = 'LEAD_ACTIVITY_DELETE_SUCCESS';
export const LEAD_ACTIVITY_DELETE_FAILED = 'LEAD_ACTIVITY_DELETE_FAILED';
export const LEAD_ACTIVITY_ADD_SUCCESS = 'LEAD_ACTIVITY_ADD_SUCCESS';
export const LEAD_ACTIVITY_ADD_FAILED = 'LEAD_ACTIVITY_ADD_FAILED';
export const LEAD_ACTIVITY_UPDATE_SUCCESS = 'LEAD_ACTIVITY_UPDATE_SUCCESS';
export const LEAD_ACTIVITY_UPDATE_FAILED = 'LEAD_ACTIVITY_UPDATE_FAILED';
export const LEAD_UPDATE_SELECTION = 'LEAD_UPDATE_SELECTION';

// Manage Quotes

export const QUOTE_MANAGE_SUCCESS = 'QUOTE_MANAGE_SUCCESS';
export const QUOTE_MANAGE_FAILED = 'QUOTE_MANAGE_FAILED';
export const UPDATE_SELECTED_QUOTE = 'UPDATE_SELECTED_QUOTE';
export const SET_SELECTED_QUOTE = 'SET_SELECTED_QUOTE';
export const TOGGLE_MODAL_QUOTES = 'TOGGLE_MODAL_QUOTES';
export const TOGGLE_MODAL_SALES_QUOTES = 'TOGGLE_MODAL_SALES_QUOTES';
export const TOGGLE_MODAL_QUOTES_DETAILS = 'TOGGLE_MODAL_QUOTES_DETAILS';
export const QUOTE_DELETE_SUCCESS = 'QUOTE_DELETE_SUCCESS';
export const QUOTE_ADD_SUCCESS = 'QUOTE_ADD_SUCCESS';
export const QUOTE_MANAGE_TOTAL_PAGES = 'QUOTE_MANAGE_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_QUOTES = 'SET_TABLE_FILTER_MANAGE_QUOTES';
export const QUOTE_UPDATE_STATUS_SUCCESS = 'QUOTE_UPDATE_STATUS_SUCCESS';
export const QUOTE_UPDATE_STATUS_FAILED = 'QUOTE_UPDATE_STATUS_FAILED';
export const TOGGLE_MODAL_ACCEPT_QUOTE = 'TOGGLE_MODAL_ACCEPT_QUOTE';
export const QUOTE_DOWNLOAD_SUCCESS = 'QUOTE_DOWNLOAD_SUCCESS';
export const QUOTE_DOWNLOAD_FAILED = 'QUOTE_DOWNLOAD_FAILED';

// manage locations
export const LOCATION_MANAGE_SUCCESS = 'LOCATION_MANAGE_SUCCESS';
export const LOCATION_MANAGE_FAILED = 'LOCATION_MANAGE_FAILED';
export const LOCATION_UPDATE_SUCCESS = 'LOCATION_UPDATE_SUCCESS';
export const LOCATION_UPDATE_FAILED = 'LOCATION_UPDATE_FAILED';
export const LOCATION_ADD_SUCCESS = 'LOCATION_ADD_SUCCESS';
export const LOCATION_ADD_FAILED = 'LOCATION_ADD_FAILED';
export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS';
export const LOCATION_DELETE_FAILED = 'LOCATION_DELETE_FAILED';
export const LOCATION_MANAGE_TOTAL_PAGES = 'LOCATION_MANAGE_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_LOCATION =
    'SET_TABLE_FILTER_MANAGE_LOCATION';
export const TOGGLE_MODAL_EDIT_LOCATION = 'TOGGLE_MODAL_EDIT_LOCATION';
export const SET_VISIBLE_LOCATIONS = 'SET_VISIBLE_LOCATIONS';

/*
 * Training Actions
 */
export const LOAD_COURSES_SUCCESS = 'LOAD_COURSES_SUCCESS';
export const LOAD_COURSES_FAILED = 'LOAD_COURSES_FAILED';
export const LOAD_LESSONS_SUCCESS = 'LOAD_LESSONS_SUCCESS';
export const LOAD_LESSONS_FAILED = 'LOAD_LESSONS_FAILED';
export const LOAD_LESSON = 'LOAD_LESSON';
export const LOAD_QUIZZES_SUCCESS = 'LOAD_QUIZZES_SUCCESS';
export const LOAD_QUIZZES_FAILED = 'LOAD_QUIZZES_FAILED';
export const LOAD_QUIZ = 'LOAD_QUIZ';
export const LOAD_QUIZZES_BY_LESSON_SUCCESS = 'LOAD_QUIZZES_BY_LESSON_SUCCESS';
export const LOAD_QUIZZES_BY_LESSON_FAILED = 'LOAD_QUIZZES_BY_LESSON_FAILED';

export const GET_ALL_LESSON_PROGRESS_SUCCESS =
    'GET_ALL_LESSON_PROGRESS_SUCCESS';
export const GET_ALL_LESSON_PROGRESS_FAILED = 'GET_ALL_LESSON_PROGRESS_FAILED';
export const GET_LESSON_PROGRESS_SUCCESS = 'GET_LESSON_PROGRESS_SUCCESS';
export const GET_LESSON_PROGRESS_FAILED = 'GET_LESSON_PROGRESS_FAILED';
export const SAVE_LESSON_PROGRESS_SUCCESS = 'SAVE_LESSON_PROGRESS_SUCCESS';
export const SAVE_LESSON_PROGRESS_FAILED = 'SAVE_LESSON_PROGRESS_FAILED';
export const SAVE_QUIZ_SUCCESS = 'SAVE_QUIZ_SUCCESS';
export const SAVE_QUIZ_FAILED = 'SAVE_QUIZ_FAILED';
export const START_QUIZ_SUCCESS = 'START_QUIZ_SUCCESS';
export const START_QUIZ_FAILED = 'START_QUIZ_FAILED';
export const GET_QUIZ_RESULTS_SUCCESS = 'GET_QUIZ_RESULTS_SUCCESS';
export const GET_QUIZ_RESULTS_FAILED = 'GET_QUIZ_RESULTS_FAILED';
export const GET_QUIZ_LINK_SUCCESS = 'GET_QUIZ_LINK_SUCCESS';
export const GET_QUIZ_LINK_FAILED = 'GET_QUIZ_LINK_FAILED';
export const ADD_ANSWER = 'ADD_ANSWER';
export const RESET_ANSWERS = 'RESET_ANSWERS';
export const SAVE_QUIZ_ANSWERS_SUCCESS = 'SAVE_QUIZ_ANSWERS_SUCCESS';
export const SAVE_QUIZ_ANSWERS_FAILED = 'SAVE_QUIZ_ANSWERS_FAILED';
export const SET_SELECTED_IN_PROGRESS_QUIZ = 'SET_SELECTED_IN_PROGRESS_QUIZ';

/*
 * MANAGE TRAINING ACTIONS
 */
export const MANAGE_TRAINING_SUCCESS = 'MANAGE_TRAINING_SUCCESS';
export const MANAGE_TRAINING_FAILED = 'MANAGE_TRAINING_FAILED';
export const MANAGE_TRAINING_TOTAL_PAGES = 'MANAGE_TRAINING_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_TRAINING =
    'SET_TABLE_FILTER_MANAGE_TRAINING';

/*
 * MANAGE MEASUREMENTS ACTIONS
 */
export const MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS =
    'MANAGE_MEASUREMENT_POINT_LISTS_SUCCESS';
export const MANAGE_MEASUREMENT_POINT_LISTS_FAILED =
    'MANAGE_MEASUREMENT_POINT_LISTS_FAILED';
export const MANAGE_MEASUREMENT_POINT_LISTS_TOTAL_PAGES =
    'MANAGE_MEASUREMENT_POINT_LISTS_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_MEASUREMENT_POINT_LISTS =
    'SET_TABLE_FILTER_MANAGE_MEASUREMENT_POINT_LISTS';
export const MEASUREMENT_POINT_LIST_SUCCESS = 'MEASUREMENT_POINT_LIST_SUCCESS';
export const MEASUREMENT_POINT_LIST_FAILED = 'MEASUREMENT_POINT_LIST_FAILED';
export const DELETE_MEASUREMENT_POINT = 'DELETE_MEASUREMENT_POINT';
export const REMOVE_MEASUREMENT_POINT_FROM_TAB =
    'REMOVE_MEASUREMENT_POINT_FROM_TAB';

export const MANAGE_MEASUREMENT_POINT_LIST_ADD =
    'MANAGE_MEASUREMENT_POINT_LIST_ADD';
export const MANAGE_MEASUREMENT_POINT_LIST_ADD_FAILED =
    'MANAGE_MEASUREMENT_POINT_LIST_ADD_FAILED';
export const MANAGE_MEASUREMENT_POINT_LIST_UPDATE =
    'MANAGE_MEASUREMENT_POINT_LIST_UPDATE';
export const MANAGE_MEASUREMENT_POINT_LIST_UPDATE_FAILED =
    'MANAGE_MEASUREMENT_POINT_LIST_UPDATE_FAILED';
export const MANAGE_MEASUREMENT_POINT_LIST_DELETE_SUCCESS =
    'MANAGE_MEASUREMENT_POINT_LIST_DELETE_SUCCESS';
export const MANAGE_MEASUREMENT_POINT_LIST_DELETE_FAILED =
    'MANAGE_MEASUREMENT_POINT_LIST_DELETE_FAILED';

export const TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_LISTS =
    'TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_LISTS';
export const TOGGLE_MODAL_EDIT_MEASUREMENT_POINT =
    'TOGGLE_MODAL_EDIT_MEASUREMENT_POINT';

export const MANAGE_MEASUREMENT_POINT_SAVE_TO_LIST =
    'MANAGE_MEASUREMENT_POINT_SAVE_TO_LIST';
export const SELECT_MEASUREMENT_POINT_LIST = 'SELECT_MEASUREMENT_POINT_LIST';
export const MANAGE_MEASUREMENT_POINT_SET_SELECTED_TAB =
    'MANAGE_MEASUREMENT_POINT_SET_SELECTED_TAB';
export const MANAGE_MEASUREMENT_POINT_UPDATE =
    'MANAGE_MEASUREMENT_POINT_UPDATE';
export const TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_TAB =
    'TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_TAB';
export const TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_LIST_TEST_PROCEDURES =
    'TOGGLE_MODAL_EDIT_MEASUREMENT_POINT_LIST_TEST_PROCEDURES';
export const MANAGE_MEASUREMENT_POINT_TAB_UPDATE =
    'MANAGE_MEASUREMENT_POINT_TAB_UPDATE';
export const MANAGE_MEASUREMENT_POINT_LISTS_DELETE_SUCCESS =
    'MANAGE_MEASUREMENT_POINT_LISTS_DELETE_SUCCESS';
export const MANAGE_MEASUREMENT_POINT_LISTS_DELETE_FAILED =
    'MANAGE_MEASUREMENT_POINT_LISTS_DELETE_FAILED';

// MEASUREMENT POINT RESULTS
export const ADD_MEASUREMENT_POINT_RESULT = 'ADD_MEASUREMENT_POINT_RESULT';
export const UPDATE_MEASUREMENT_POINT_RESULT =
    'UPDATE_MEASUREMENT_POINT_RESULT';
export const GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS =
    'GET_MEASUREMENT_POINT_FACILITY_RESULTS_SUCCESS';
export const GET_MEASUREMENT_POINT_FACILITY_RESULTS_FAILED =
    'GET_MEASUREMENT_POINT_FACILITY_RESULTS_FAILED';
export const RESET_MEASUREMENT_POINT_RESULT = 'RESET_MEASUREMENT_POINT_RESULT';
export const REMOVE_NOT_TESTED = 'REMOVE_NOT_TESTED';
export const SET_NOT_TESTED = 'SET_NOT_TESTED';
export const TOGGLE_MODAL_TEST_PROCEDURES = 'TOGGLE_MODAL_TEST_PROCEDURES';
export const SET_HISTORICAL_RESULT_ID = 'SET_HISTORICAL_RESULT_ID';
export const CLEAR_HISTORICAL_RESULT_ID = 'CLEAR_HISTORICAL_RESULT_ID';
export const SET_PREVIOUS_RESULT = 'SET_PREVIOUS_RESULT';
export const RESET_PREVIOUS_RESULT = 'RESET_PREVIOUS_RESULT';
export const GET_MEASUREMENT_POINT_RESULT_SUCCESS =
    'GET_MEASUREMENT_POINT_RESULT_SUCCESS';
export const GET_MEASUREMENT_POINT_RESULT_FAILED =
    'GET_MEASUREMENT_POINT_RESULT_FAILED';
export const GET_MEASUREMENT_POINT_HISTORY_BY_INSTALLBASE_SUCCESS =
    'GET_MEASUREMENT_POINT_HISTORY_BY_INSTALLBASE_SUCCESS';
export const GET_MEASUREMENT_POINT_HISTORY_BY_INSTALLBASE_FAILED =
    'GET_MEASUREMENT_POINT_HISTORY_BY_INSTALLBASE_FAILED';

/*
 * MANAGE REPORT
 */
/*
 * MANAGE REPORT
 */
export const REPORT_MANAGE_GET_DEFAULT_SUCCESS =
    'REPORT_MANAGE_GET_DEFAULT_SUCCESS';
export const REPORT_MANAGE_GET_DEFAULT_FAILED =
    'REPORT_MANAGE_GET_DEFAULT_FAILED';
export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_ADD_SUCCESS = 'REPORT_ADD_SUCCESS';
export const REPORT_ADD_FAILED = 'REPORT_ADD_FAILED';
export const REPORT_MANAGE_TOTAL_PAGES = 'REPORT_MANAGE_TOTAL_PAGES';
export const SET_TABLE_FILTER_MANAGE_REPORT = 'SET_TABLE_FILTER_MANAGE_REPORT';
export const TOGGLE_MODAL_EDIT_REPORT = 'TOGGLE_MODAL_EDIT_REPORT';
export const SET_SELECTED_REPORT = 'SET_SELECTED_REPORT';
export const SET_SELECTED_DEFAULT_REPORT_ID = 'SET_SELECTED_DEFAULT_REPORT_ID';
export const CLEAR_SELECTED_DEFAULT_REPORT_ID =
    'CLEAR_SELECTED_DEFAULT_REPORT_ID';

/*
 * REPORT TEMPLATES
 */
export const REPORT_TEMPLATES_GET_SUCCESS = 'REPORT_TEMPLATES_GET_SUCCESS';
export const REPORT_TEMPLATES_GET_FAILED = 'REPORT_TEMPLATES_GET_FAILED';
export const REPORT_TEMPLATES_CREATE_SUCCESS =
    'REPORT_TEMPLATES_CREATE_SUCCESS';
export const REPORT_TEMPLATES_CREATE_FAILED = 'REPORT_TEMPLATES_CREATE_FAILED';
export const REPORT_TEMPLATES_UPDATE_SUCCESS =
    'REPORT_TEMPLATES_UPDATE_SUCCESS';
export const REPORT_TEMPLATES_UPDATE_FAILED = 'REPORT_TEMPLATES_UPDATE_FAILED';
export const REPORT_TEMPLATES_DELETE_SUCCESS =
    'REPORT_TEMPLATES_DELETE_SUCCESS';
export const REPORT_TEMPLATES_DELETE_FAILED = 'REPORT_TEMPLATES_DELETE_FAILED';

/*
 * REPORT CHAPTERS
 */
export const REPORT_CHAPTERS_GET_SUCCESS = 'REPORT_CHAPTERS_GET_SUCCESS';
export const REPORT_CHAPTERS_GET_FAILED = 'REPORT_CHAPTERS_GET_FAILED';
export const REPORT_CHAPTERS_UPDATE_SUCCESS = 'REPORT_CHAPTERS_UPDATE_SUCCESS';
export const REPORT_CHAPTERS_UPDATE_FAILED = 'REPORT_CHAPTERS_UPDATE_FAILED';
export const REPORT_CHAPTERS_DELETE_SUCCESS = 'REPORT_CHAPTERS_DELETE_SUCCESS';
export const REPORT_CHAPTERS_DELETE_FAILED = 'REPORT_CHAPTERS_DELETE_FAILED';

/*
 * REPORT TEMPLATE CHAPTERS
 */
export const REPORT_TEMPLATE_CHAPTERS_GET_SUCCESS =
    'REPORT_TEMPLATE_CHAPTERS_GET_SUCCESS';
export const REPORT_TEMPLATE_CHAPTERS_GET_FAILED =
    'REPORT_TEMPLATE_CHAPTERS_GET_FAILED';
export const REPORT_TEMPLATE_CHAPTERS_CREATE_SUCCESS =
    'REPORT_TEMPLATE_CHAPTERS_CREATE_SUCCESS';
export const REPORT_TEMPLATE_CHAPTERS_CREATE_FAILED =
    'REPORT_TEMPLATE_CHAPTERS_CREATE_FAILED';
export const REPORT_TEMPLATE_CHAPTERS_UPDATE_SUCCESS =
    'REPORT_TEMPLATE_CHAPTERS_UPDATE_SUCCESS';
export const REPORT_TEMPLATE_CHAPTERS_UPDATE_FAILED =
    'REPORT_TEMPLATE_CHAPTERS_UPDATE_FAILED';
export const REPORT_TEMPLATE_CHAPTERS_DELETE_SUCCESS =
    'REPORT_TEMPLATE_CHAPTERS_DELETE_SUCCESS';
export const REPORT_TEMPLATE_CHAPTERS_DELETE_FAILED =
    'REPORT_TEMPLATE_CHAPTERS_DELETE_FAILED';

// brands
export const LOAD_BRANDS_SUCCESS = 'LOAD_BRANDS_SUCCESS';
export const ADD_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS';
export const EDIT_BRAND_SUCCESS = 'EDIT_BRAND_SUCCESS';
export const REMOVE_BRAND_SUCCESS = 'REMOVE_BRAND_SUCCESS';
export const ADD_BRAND_LOGO_SUCCESS = 'ADD_BRAND_LOGO_SUCCESS';

export const ADD_BRAND_FAILED = 'ADD_BRAND_FAILED';
export const EDIT_BRAND_FAILED = 'EDIT_BRAND_FAILED';
export const REMOVE_BRAND_FAILED = 'REMOVE_BRAND_FAILED';
export const ADD_BRAND_LOGO_FAILED = 'ADD_BRAND_LOGO_FAILED';

export const TOGGLE_MODAL_EDIT_BRAND = 'TOGGLE_MODAL_EDIT_BRAND';
export const SET_TABLE_FILTER_MANAGE_BRAND = 'SET_TABLE_FILTER_MANAGE_BRAND';
export const BRAND_MANAGE_TOTAL_PAGES = 'BRAND_MANAGE_TOTAL_PAGES';
export const SET_SELECTED_BRAND_ID = 'SET_SELECTED_BRAND_ID';
export const CLEAR_SELECTED_BRAND_ID = 'CLEAR_SELECTED_BRAND_ID';
export const UPDATE_SELECTED_BRAND = 'UPDATE_SELECTED_BRAND';

// Documents
export const LOAD_DOCUMENT_TYPES_SUCCESS = 'LOAD_DOCUMENT_TYPES_SUCCESS';
export const LOAD_DOCUMENTS_SUCCESS = 'LOAD_DOCUMENTS_SUCCESS';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_VERSION_SUCCESS = 'ADD_DOCUMENT_VERSION_SUCCESS';
export const EDIT_DOCUMENT_SUCCESS = 'EDIT_DOCUMENT_SUCCESS';
export const REMOVE_DOCUMENT_SUCCESS = 'REMOVE_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_FAILED = 'DOWNLOAD_DOCUMENT_FAILED';

export const LOAD_DOCUMENT_TYPES_FAILED = 'LOAD_DOCUMENT_TYPES_FAILED';
export const LOAD_DOCUMENTS_FAILED = 'LOAD_DOCUMENTS_FAILED';
export const ADD_DOCUMENT_FAILED = 'ADD_DOCUMENT_FAILED';
export const EDIT_DOCUMENT_FAILED = 'EDIT_DOCUMENT_FAILED';
export const REMOVE_DOCUMENT_FAILED = 'REMOVE_DOCUMENT_FAILED';

export const TOGGLE_MODAL_EDIT_DOCUMENT = 'TOGGLE_MODAL_EDIT_DOCUMENT';
export const SET_TABLE_FILTER_MANAGE_DOCUMENT =
    'SET_TABLE_FILTER_MANAGE_DOCUMENT';
export const CLEAR_TABLE_FILTERS_MANAGE_DOCUMENT =
    'CLEAR_TABLE_FILTERS_MANAGE_DOCUMENT';
export const DOCUMENT_MANAGE_TOTAL_PAGES = 'DOCUMENT_MANAGE_TOTAL_PAGES';
export const SET_SELECTED_PARENT_ID = 'SET_SELECTED_PARENT_ID';
export const CLEAR_SELECTED_PARENT_ID = 'CLEAR_SELECTED_PARENT_ID';
export const SET_SELECTED_DOCUMENT_ID = 'SET_SELECTED_DOCUMENT_ID';
export const CLEAR_SELECTED_DOCUMENT_ID = 'CLEAR_SELECTED_DOCUMENT_ID';
export const SET_SELECTED_DOCUMENT_TYPE_ID = 'SET_SELECTED_DOCUMENT_TYPE_ID';
export const CLEAR_SELECTED_DOCUMENT_TYPE_ID =
    'CLEAR_SELECTED_DOCUMENT_TYPE_ID';

export const SET_FORM_VALUES_MANAGE_DOCUMENT =
    'SET_FORM_VALUES_MANAGE_DOCUMENT';
export const UPDATE_FORM_VALUES_MANAGE_DOCUMENT =
    'UPDATE_FORM_VALUES_MANAGE_DOCUMENT';

export const SET_FORM_VALUES_MANAGE_DOCUMENT_VERSION =
    'SET_FORM_VALUES_MANAGE_DOCUMENT_VERSION';
export const UPDATE_FORM_VALUES_MANAGE_DOCUMENT_VERSION =
    'UPDATE_FORM_VALUES_MANAGE_DOCUMENT_VERSION';

// alerts
export const LOAD_ALERTS_SUCCESS = 'LOAD_ALERTS_SUCCESS';
export const ADD_ALERT_SUCCESS = 'ADD_ALERT_SUCCESS';
export const EDIT_ALERT_SUCCESS = 'EDIT_ALERT_SUCCESS';
export const REMOVE_ALERT_SUCCESS = 'REMOVE_ALERT_SUCCESS';
export const ADD_USER_ALERT = 'ADD_USER_ALERT';

export const ADD_ALERT_FAILED = 'ADD_ALERT_FAILED';
export const EDIT_ALERT_FAILED = 'EDIT_ALERT_FAILED';
export const REMOVE_ALERT_FAILED = 'REMOVE_ALERT_FAILED';

export const TOGGLE_MODAL_EDIT_ALERT = 'TOGGLE_MODAL_EDIT_ALERT';
export const SET_TABLE_FILTER_MANAGE_ALERT = 'SET_TABLE_FILTER_MANAGE_ALERT';
export const ALERT_MANAGE_TOTAL_PAGES = 'ALERT_MANAGE_TOTAL_PAGES';
export const SET_SELECTED_ALERT_ID = 'SET_SELECTED_ALERT_ID';
export const CLEAR_SELECTED_ALERT_ID = 'CLEAR_SELECTED_ALERT_ID';

export const SET_FORM_VALUES_MANAGE_ALERT = 'SET_FORM_VALUES_MANAGE_ALERT';
export const UPDATE_FORM_VALUES_MANAGE_ALERT =
    'UPDATE_FORM_VALUES_MANAGE_ALERT';

// codes

export const LOAD_CODES_SUCCESS = 'LOAD_CODES_SUCCESS';
export const ADD_CODE_SUCCESS = 'ADD_CODE_SUCCESS';
export const EDIT_CODE_SUCCESS = 'EDIT_CODE_SUCCESS';
export const REMOVE_CODE_SUCCESS = 'REMOVE_CODE_SUCCESS';

export const ADD_CODE_FAILED = 'ADD_CODE_FAILED';
export const EDIT_CODE_FAILED = 'EDIT_CODE_FAILED';
export const REMOVE_CODE_FAILED = 'REMOVE_CODE_FAILED';

export const TOGGLE_MODAL_EDIT_CODE = 'TOGGLE_MODAL_EDIT_CODE';
export const SET_TABLE_FILTER_MANAGE_CODE = 'SET_TABLE_FILTER_MANAGE_CODE';
export const CODE_MANAGE_TOTAL_PAGES = 'CODE_MANAGE_TOTAL_PAGES';
export const SET_SELECTED_CODE_ID = 'SET_SELECTED_CODE_ID';
export const CLEAR_SELECTED_CODE_ID = 'CLEAR_SELECTED_CODE_ID';

export const SET_FORM_VALUES_MANAGE_CODE = 'SET_FORM_VALUES_MANAGE_CODE';
export const UPDATE_FORM_VALUES_MANAGE_CODE = 'UPDATE_FORM_VALUES_MANAGE_CODE';

// work orders

export const CLEAR_WORKORDERS = 'CLEAR_WORKORDERS';
export const LOAD_WORKORDERS_SUCCESS = 'LOAD_WORKORDERS_SUCCESS';
export const LOAD_WORKORDERS_FAILED = 'LOAD_WORKORDERS_FAILED';
export const LOAD_WORKORDER_TECHNICIANS_SUCCESS =
    'LOAD_WORKORDER_TECHNICIANS_SUCCESS';
export const LOAD_WORKORDER_TECHNICIANS_FAILED =
    'LOAD_WORKORDER_TECHNICIANS_FAILED';
export const LOAD_WORKORDER_CSV_SUCCESS = 'LOAD_WORKORDER_CSV_SUCCESS';
export const LOAD_WORKORDER_CSV_FAILED = 'LOAD_WORKORDER_CSV_FAILED';

export const ADD_WORKORDER_SUCCESS = 'ADD_WORKORDER_SUCCESS';
export const ADD_WORKORDER_FAILED = 'ADD_WORKORDER_FAILED';
export const EDIT_WORKORDER_SUCCESS = 'EDIT_WORKORDER_SUCCESS';
export const EDIT_WORKORDER_FAILED = 'EDIT_WORKORDER_FAILED';
export const REMOVE_WORKORDER_SUCCESS = 'REMOVE_WORKORDER_SUCCESS';
export const REMOVE_WORKORDER_FAILED = 'REMOVE_WORKORDER_FAILED';

export const UNLINK_WORKORDERS_SUCCESS = 'UNLINK_WORKORDERS_SUCCESS';
export const UNLINK_WORKORDERS_FAILED = 'UNLINK_WORKORDERS_FAILED';

export const TOGGLE_MODAL_EDIT_WORKORDER = 'TOGGLE_MODAL_EDIT_WORKORDER';
export const TOGGLE_MODAL_CLOSING_NOTES = 'TOGGLE_MODAL_CLOSING_NOTES';

export const SET_SELECTED_PART_ID = 'SET_SELECTED_PART_ID';
export const CLEAR_SELECTED_PART_ID = 'CLEAR_SELECTED_PART_ID';
export const TOGGLE_MODAL_EDIT_PART = 'TOGGLE_MODAL_EDIT_PART';

export const SET_TABLE_FILTER_MANAGE_WORKORDER =
    'SET_TABLE_FILTER_MANAGE_WORKORDER';
export const WORKORDER_MANAGE_TOTAL_PAGES = 'WORKORDER_MANAGE_TOTAL_PAGES';
export const SET_SELECTED_WORKORDER_ID = 'SET_SELECTED_WORKORDER_ID';
export const CLEAR_SELECTED_WORKORDER_ID = 'CLEAR_SELECTED_WORKORDER_ID';

export const SET_FORM_VALUES_MANAGE_WORKORDER =
    'SET_FORM_VALUES_MANAGE_WORKORDER';
export const UPDATE_FORM_VALUES_MANAGE_WORKORDER =
    'UPDATE_FORM_VALUES_MANAGE_WORKORDER';
export const SET_FORM_VALUES_MANAGE_WORKORDER_PART =
    'SET_FORM_VALUES_MANAGE_WORKORDER_PART';
export const UPDATE_FORM_VALUES_MANAGE_WORKORDER_PART =
    'UPDATE_FORM_VALUES_MANAGE_WORKORDER_PART';
export const GET_WORK_ORDERS_BY_FACILITY_SUCCESS =
    'GET_WORK_ORDERS_BY_FACILITY_SUCCESS';
export const GET_WORK_ORDERS_BY_FACILITY_FAILED =
    'GET_WORK_ORDERS_BY_FACILITY_FAILED';
export const TOGGLE_MODAL_CONFIRM_SELECT_JOB =
    'TOGGLE_MODAL_CONFIRM_SELECT_JOB';
export const WORKORDER_UPDATE_SELECTION = 'WORKORDER_UPDATE_SELECTION';
export const SET_SELECTED_MANAGE_WORKORDER_FACILITY_ID =
    'SET_SELECTED_MANAGE_WORKORDER_FACILITY_ID';
export const CLEAR_SELECTED_MANAGE_WORKORDER_FACILITY_ID =
    'CLEAR_SELECTED_MANAGE_WORKORDER_FACILITY_ID';
export const GET_FSES_WITH_WORK_ORDERS_SUCCESS =
    'GET_FSES_WITH_WORK_ORDERS_SUCCESS';
export const GET_FSES_WITH_WORK_ORDERS_FAILED =
    'GET_FSES_WITH_WORK_ORDERS_FAILED';

// User Alerts
export const LOAD_USER_ALERTS_SUCCESS = 'LOAD_USER_ALERTS_SUCCESS';
export const LOAD_USER_ALERTS_FAILED = 'LOAD_USER_ALERTS_FAILED';
export const READ_USER_ALERT_SUCCESS = 'READ_USER_ALERT_SUCCESS';
export const READ_USER_ALERT_FAILED = 'READ_USER_ALERT_FAILED';
export const READ_ALL_USER_ALERTS_SUCCESS = 'READ_ALL_USER_ALERT_SUCCESS';
export const READ_ALL_USER_ALERTS_FAILED = 'READ_ALL_USER_ALERT_FAILED';
export const TOGGLE_MODAL_USER_ALERT = 'TOGGLE_MODAL_USER_ALERT';

// Facility
export const GET_FACILITY_SUCCESS = 'GET_FACILITY_SUCCESS';
export const GET_FACILITY_FAILED = 'GET_FACILITY_FAILED';
export const FACILITY_TOTAL_PAGES = 'FACILITY_TOTAL_PAGES';
export const ADD_FACILITY_SUCCESS = 'ADD_FACILITY_SUCCESS';
export const EDIT_FACILITY_SUCCESS = 'EDIT_FACILITY_SUCCESS';

export const SET_SELECTED_FACILITY_ID = 'SET_SELECTED_FACILITY_ID';
export const CLEAR_SELECTED_FACILITY_ID = 'CLEAR_SELECTED_FACILITY_ID';
export const SET_TABLE_FILTER_MANAGE_FACILITY =
    'SET_TABLE_FILTER_MANAGE_FACILITY';

export const UPDATE_FORM_VALUES_MANAGE_FACILITY =
    'UPDATE_FORM_VALUES_MANAGE_FACILITY';
export const SET_FORM_VALUES_MANAGE_FACILITY =
    'SET_FORM_VALUES_MANAGE_FACILITY';

export const GLOBAL_SET_SELECTED_CUSTOMER_ID = `GLOBAL_SET_SELECTED_CUSTOMER_ID`;
export const GLOBAL_SET_SELECTED_FACILITY_ID =
    'GLOBAL_SET_SELECTED_FACILITY_ID';
export const GLOBAL_CLEAR_SELECTED_FACILITY_ID =
    'GLOBAL_CLEAR_SELECTED_FACILITY_ID';
export const SET_GLOBAL_CUSTOMER_ID_VIA_FACILITY_ID =
    'SET_GLOBAL_CUSTOMER_ID_VIA_FACILITY_ID';

// Dashboard Analytics

export const GET_DASHBOARD_ANALYTICS_SUCCESS =
    'GET_DASHBOARD_ANALYTICS_SUCCESS';
export const GET_DASHBOARD_ANALYTICS_FAILED = 'GET_DASHBOARD_ANALYTICS_FAILED';

// Job Comments
export const MANAGE_JOB_COMMENTS_SUCCESS = 'MANAGE_JOB_COMMENTS_SUCCESS';
export const MANAGE_JOB_COMMENTS_FAILED = 'MANAGE_JOB_COMMENTS_FAILED';
export const MANAGE_JOB_COMMENTS_ADD = 'MANAGE_JOB_COMMENTS_ADD';
export const MANAGE_JOB_COMMENTS_UPDATE = 'MANAGE_JOB_COMMENTS_UPDATE';
export const MANAGE_JOB_COMMENTS_SELECT = 'MANAGE_JOB_COMMENTS_SELECT';
export const TOGGLE_MODAL_EDIT_JOB_COMMENT = 'TOGGLE_MODAL_EDIT_JOB_COMMENT';
export const MANAGE_JOB_COMMENTS_FILTER_VISIBLE =
    'MANAGE_JOB_COMMENTS_FILTER_VISIBLE';
export const SET_TABLE_FILTER_MANAGE_JOB_COMMENTS =
    'SET_TABLE_FILTER_MANAGE_JOB_COMMENTS';
export const MANAGE_JOB_COMMENT_CODES_SUCCESS =
    'MANAGE_JOB_COMMENT_CODES_SUCCESS';
export const MANAGE_JOB_COMMENT_CODES_FAILED =
    'MANAGE_JOB_COMMENT_CODES_FAILED';

// Manage TAE

export const MANAGE_TAE_SUCCESS = 'MANAGE_TAE_SUCCESS';
export const MANAGE_TAE_FAILED = 'MANAGE_TAE_FAILED';
export const TOGGLE_MODAL_EDIT_TAE = 'TOGGLE_MODAL_EDIT_TAE';
export const SET_TABLE_FILTER_MANAGE_TAE = 'SET_TABLE_FILTER_MANAGE_TAE';
export const SET_SELECTED_MANAGE_TAE = 'SET_SELECTED_MANAGE_TAE';

// Manage Leads
export const TOGGLE_MODAL_LEADS = 'TOGGLE_MODAL_LEADS';
// SideMenu
export const SET_CURRENT_MENU_ITEM = 'SET_CURRENT_MENU_ITEM';
export const SET_SELECTED_MENU_ITEM = 'SET_SELECTED_MENU_ITEM';

// Video Lesson
export const ADD_VIEWED_TOUR = 'ADD_VIEWED_TOUR';
export const RESET_VIEWED = 'RESET_VIEWED';
export const SET_IS_TUTORIAL_OPEN = 'SET_IS_TUTORIAL_OPEN';
export const TOGGLE_TUTORIAL = 'TOGGLE_TUTORIAL';

// Manage Asset Photos
export const ASSET_PHOTOS_MANAGE_SUCCESS = 'ASSET_PHOTOS_MANAGE_SUCCESS';
export const ASSET_PHOTOS_MANAGE_FAILED = 'ASSET_PHOTOS_MANAGE_FAILED';
export const SET_TABLE_FILTER_MANAGE_ASSET_PHOTOS =
    'SET_TABLE_FILTER_MANAGE_ASSET_PHOTOS';
export const ASSET_PHOTOS_MANAGE_TOTAL_PAGES =
    'ASSET_PHOTOS_MANAGE_TOTAL_PAGES';
export const UPLOAD_PRODUCT_PHOTO_SUCCESS = 'UPLOAD_PRODUCT_PHOTO_SUCCESS';
export const UPLOAD_PRODUCT_PHOTO_FAILED = 'UPLOAD_PRODUCT_PHOTO_FAILED';
export const PHOTOS_BY_PRODUCT_SUCCESS = 'PHOTOS_BY_PRODUCT_SUCCESS';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILED = 'DELETE_PHOTO_FAILED';

export const SAVE_JOB_PARTS_SUCCESS = 'SAVE_JOB_PARTS_SUCCESS';
export const SAVE_JOB_PARTS_FAILED = 'SAVE_JOB_PARTS_FAILED';

export const GET_JOB_PARTS_SUCCESS = 'GET_JOB_PARTS_SUCCESS';
export const GET_JOB_PARTS_FAILED = 'GET_JOB_PARTS_FAILED';

export const GET_NEXT_JOB_NUMBER_SUCCESS = 'GET_NEXT_JOB_NUMBER_SUCCESS';
export const GET_NEXT_JOB_NUMBER_FAILED = 'GET_NEXT_JOB_NUMBER_FAILED';

export const TOGGLE_MODAL_EDIT_SUGGESTED_PART =
    'TOGGLE_MODAL_EDIT_SUGGESTED_PART';
export const SET_TABLE_FILTER_MANAGE_SUGGESTED_PARTS =
    'SET_TABLE_FILTER_MANAGE_SUGGESTED_PARTS';
export const SUGGESTED_PARTS_MANAGE_TOTAL_PAGES =
    'SUGGESTED_PARTS_MANAGE_TOTAL_PAGES';

export const SEARCH_SUGGESTED_PARTS_SUCCESS = 'SEARCH_SUGGESTED_PARTS_SUCCESS';
export const SEARCH_SUGGESTED_PARTS_FAILED = 'SEARCH_SUGGESTED_PARTS_FAILED';
export const UPDATE_SELECTED_SUGGESTED_PART = 'UPDATE_SELECTED_SUGGESTED_PART';

export const SAVE_SUGGESTED_PARTS_SUCCESS = 'SAVE_SUGGESTED_PARTS_SUCCESS';
export const SAVE_SUGGESTED_PARTS_FAILED = 'SAVE_SUGGESTED_PARTS_FAILED';

// Config
export const SET_IS_MOBILE = 'SET_IS_MOBILE';

// Countries
export const SET_COUNTRIES = 'SET_COUNTRIES';

export const SET_SAP_JOB_MAPPING = 'SET_SAP_JOB_MAPPING';
