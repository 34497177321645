import { beginAjaxCall } from './ajaxStatusActions';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import API from '../constants/apiEndpoints';
import { msalFetch } from '../components/auth/Auth-Utils';

import * as types from './actionTypes';
import { constants } from '../constants/constants';
import { toastr } from 'react-redux-toastr';
import { Ibrand, ItableFiltersParams, ThunkResult } from '../models';
import { FormUtil } from '../components/common/FormUtil';
import { initialBrand } from '../reducers/initialState';

const addBrandLogo = async (brandID: string, file: string) => {
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: {
            brandID,
            file
        }
    };
    const url = API.PUT.brandLogo;

    return msalFetch(url, axiosOptions);
};

export function getAllBrands(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: {}
        };

        const url = API.GET.brand.getAll;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LOAD_BRANDS_SUCCESS,
                        payload: { result: data.data }
                    });

                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.LOAD_BRANDS_SUCCESS });
                constants.handleError(error, 'get brands');
                console.error(error);
            });
    };
}

export function getBrands(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const { page, name } = getState().manageBrand.tableFilters;
        const axiosOptions: AxiosRequestConfig = {
            method: 'get',
            params: { page, name }
        };

        const url = API.GET.brand.search;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.LOAD_BRANDS_SUCCESS,
                        payload: data.data
                    });
                    dispatch({
                        type: types.BRAND_MANAGE_TOTAL_PAGES,
                        pages: data.data.pages
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({ type: types.LOAD_BRANDS_SUCCESS });
                constants.handleError(error, 'get brands');
                console.error(error);
            });
    };
}

export function updateBrand(brand: Ibrand): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        let brandToUpdate = { ...brand };
        if (brand.countryID === '') {
            brandToUpdate.countryID = undefined;
        }

        const axiosOptions: AxiosRequestConfig = {
            method: 'put',
            data: { ...brandToUpdate }
        };

        const url = `${API.PUT.brand}/${brand.id}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.EDIT_BRAND_SUCCESS,
                    payload: brand
                });
                const productInfo = getState().productInfo;
                // Check if brands are loaded in inventory
                if (productInfo.brandOptions?.length > 0) {
                    const brands = {
                        ...productInfo.allBrands,
                        [brand.id]: brand
                    };
                    // Update brand in inventory brand list
                    dispatch({
                        type: types.UPDATE_PRODUCT_INFO_BRANDS_SUCCESS,
                        payload: { brands }
                    });
                }
                // dispatch({ type: types.TOGGLE_MODAL_EDIT_BRAND });
                toastr.success(
                    'Success',
                    `Updated Brand.`,
                    constants.toastrSuccess
                );

                if (brand.logo) {
                    const base64Logo = brand.logo?.replace(
                        /^[\w/:.-]+;base64,/g,
                        ''
                    );
                    addBrandLogo(brand.id, base64Logo);
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.EDIT_BRAND_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get brands');
                console.error(error);
            });
    };
}

export function saveBrand(brand: Ibrand, isEditing: boolean): ThunkResult<any> {
    return (dispatch, getState) => {
        // We are creating a brand new Brand
        if (isEditing === false) {
            dispatch(beginAjaxCall());
            const axiosOptions: AxiosRequestConfig = {
                method: 'post',
                data: { name: brand.name }
            };

            const url = API.POST.brand;
            return msalFetch(url, axiosOptions)
                .then((data: AxiosResponse<any>) => {
                    const newBrand = {
                        ...brand,
                        id: data.data.id,
                        createDate: data.data.createDate,
                        updateDate: data.data.updateDate
                    };
                    dispatch({
                        type: types.ADD_BRAND_SUCCESS,
                        payload: newBrand
                    });
                    const inventoryProductInfo = getState().productInfo;
                    // Check if brands are loaded in inventory
                    if (
                        inventoryProductInfo.brandOptions &&
                        !!inventoryProductInfo.brandOptions.length
                    ) {
                        const brands = {
                            [newBrand.id]: newBrand,
                            ...inventoryProductInfo.brands
                        };
                        const brandOptions = [
                            FormUtil.convertToSingleOption(newBrand),
                            ...inventoryProductInfo.brandOptions
                        ];
                        // Add new brand in inventory brand list
                        dispatch({
                            type: types.UPDATE_PRODUCT_INFO_SUCCESS,
                            payload: { brands, brandOptions }
                        });
                    }
                    dispatch(updateBrand(newBrand));

                    // dispatch({ type: types.TOGGLE_MODAL_EDIT_BRAND });
                    toastr.success(
                        'Success',
                        `Created Brand.`,
                        constants.toastrSuccess
                    );
                })
                .catch((error: any) => {
                    dispatch({
                        type: types.ADD_BRAND_FAILED,
                        error,
                        axiosOptions
                    });
                    constants.handleError(error, 'get brands');
                    console.error(error);
                });
        } else {
            // We are updating
            dispatch(updateBrand(brand));
        }
    };
}

export function deleteBrand(brand: any): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'delete'
        };

        const url = `${API.DELETE.brand}/${brand.id}`;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.REMOVE_BRAND_SUCCESS,
                    payload: brand
                });
                const inventoryProductInfo = getState().productInfo;
                // Check if brands are loaded in inventory
                if (
                    inventoryProductInfo.brandOptions &&
                    !!inventoryProductInfo.brandOptions.length
                ) {
                    const brands = { ...inventoryProductInfo.brands };
                    if (brands.hasOwnProperty(brand.id)) {
                        delete brands[brand.id];
                    }
                    const brandOptions = inventoryProductInfo.brandOptions.filter(
                        (b: any) => b.value !== brand.id
                    );
                    // Update brand in inventory brand list
                    dispatch({
                        type: types.UPDATE_PRODUCT_INFO_SUCCESS,
                        payload: { brands, brandOptions }
                    });
                }
                // dispatch({type: types.TOGGLE_MODAL_EDIT_BRAND});
                toastr.success(
                    'Success',
                    `Deleted Brand.`,
                    constants.toastrSuccess
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.REMOVE_BRAND_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get brands');
                console.error(error);
            });
    };
}

/*
 * updateSelectedJob
 * update the selected brand
 */
export const updateSelectedBrand = (job = initialBrand) => ({
    type: types.UPDATE_SELECTED_BRAND,
    payload: job
});

export const setSelectedBrandID = (id: string) => ({
    type: types.SET_SELECTED_BRAND_ID,
    id
});

export const clearSelectedBrandID = () => ({
    type: types.CLEAR_SELECTED_BRAND_ID
});

export const toggleEditBrandModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_BRAND
});

export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_BRAND,
    filters
});
