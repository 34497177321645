export const securityFunctions = {
    // RunDailyRoundsReports: {
    //     id: '0FF13067-D224-4BA7-883B-9CC07788B55A',
    //     name: 'securityF:RunDailyReports',
    //     description: 'securityF:RunDailyReportsDescription'
    // },
    ManageAllUsers: {
        id: 'AA6F93B7-D278-4117-9B14-26DFA795742E',
        name: 'securityF:ManageAllUsers',
        description: 'securityF:ManageAllUsersDescription'
    },
    ManageJobs: {
        // Beacon Admin only
        id: '097AAE49-75FD-4D2B-91EF-967BE665D565',
        name: 'securityF:ManageJobs',
        description: 'securityF:ManageJobsDescription'
    },
    FSE: {
        id: '9685F92C-F78E-4C70-9961-5E3068030242',
        name: 'securityF:FSE',
        description: 'securityF:FSEDescription'
    },
    ViewJobs: {
        id: '362838EB-A081-4D87-A231-1B427A481916',
        name: 'securityF:ViewJobs',
        description: 'securityF:ViewJobsDescription'
    },
    ManageTrainingPayment: {
        id: '3A0D4616-4179-4BA1-98F0-6A929A3A5E0D',
        name: 'securityF:ManageTrainingPayment',
        description: 'securityF:ManageTrainingPaymentDescription'
    },
    ManageSecurity: {
        id: 'B397D3EB-D55E-416A-9C48-AFE858AC5091',
        name: 'securityF:ManageSecurity',
        description: 'securityF:ManageSecurityDescription'
    },
    ViewInventory: {
        id: 'A98B1372-81D3-43E1-A81A-3F382CE83542',
        name: 'securityF:ViewInventory',
        description: 'securityF:ViewInventoryDescription'
    },
    ViewAssetPhotos: {
        id: '51EE06A2-9314-493F-8706-1F9479D7B42B',
        name: 'securityF:ViewAssetPhotos',
        description: 'securityF:ViewAssetPhotosDescription'
    },
    ManageInventory: {
        id: 'DD65AB77-23D6-4FE0-9AD7-5729B6E5C40D',
        name: 'securityF:ManageInventory',
        description: 'securityF:ManageInventoryDescription'
    },
    QuoteForInvoice: {
        id: 'DF7EA0C4-98C3-40BF-9E73-03E5BF50F9D0',
        name: 'securityF:QuoteForInvoice',
        description: 'securityF:QuoteForInvoiceDescription'
    },
    SignUpTeamMembers: {
        id: '947CF4F4-10AC-4A38-BA7B-F069605E7A6C',
        name: 'securityF:SignUpTeamMembers',
        description: 'securityF:SignUpTeamMembersDescription'
    },
    ManageTeamMembers: {
        id: 'C75A644C-54A5-4EA3-95D2-AB58D2A39E8E',
        name: 'securityF:ManageTeamMembers',
        description: 'securityF:ManageTeamMembersDescription'
    },
    ManageIndividualTraining: {
        id: '977049C6-3EB6-40CF-938F-C3B766C5EECD',
        name: 'securityF:ManageIndividualTraining',
        description: 'securityF:ManageIndividualTrainingDescription'
    },
    ManageEmployeeTraining: {
        id: '26785416-C391-4B14-AE9F-B5A56F8D223E',
        name: 'securityF:ManageEmployeeTraining',
        description: 'securityF:ManageEmployeeTrainingDescription'
    },
    Payment: {
        id: '655401EA-4AA1-4543-91E0-9F5A3AB754C3',
        name: 'securityF:Payment',
        description: 'securityF:PaymentDescription'
    },
    ManageProducts: {
        id: '64818AA5-A685-4E5D-AD22-E2BB357CF58B',
        name: 'securityF:ManageProducts',
        description: 'securityF:ManageProductsDescription'
    },
    ManageLocations: {
        id: '0FE683B3-FEA5-4130-9243-2C272CABA674',
        name: 'securityF:ManageLocations',
        description: 'securityF:ManageLocationsDescription'
    },
    ManageAllTraining: {
        id: '25961E0B-AFB4-4864-BF4C-A341A22553C6',
        name: 'securityF:ManageAllTraining',
        description: 'securityF:ManageAllTrainingDescription'
    },
    ManageAllMeasurementPoints: {
        id: 'BE9173D7-B8AA-4065-973F-7B39A2226221',
        name: 'securityF:ManageAllMeasurementPoints',
        description: 'securityF:ManageAllMeasurementPointsDescription'
    },
    ManageCustomerMeasurementPoints: {
        id: '4EA1668B-220A-4FFB-865B-EB271EF6FF0E',
        name: 'securityF:ManageCustomerMeasurementPoints',
        description: 'securityF:ManageCustomerMeasurementPointsDescription'
    },
    ManageFacilities: {
        id: '9FC06116-3A12-4564-B49B-BC3B7C6218C5',
        name: 'securityF:ManageFacilities',
        description: 'securityF:ManageFacilitiesDescription'
    },
    ManageBrands: {
        id: '6701ED68-A98C-4F62-BB4D-02219E88804B',
        name: 'securityF:ManageBrands',
        description: 'securityF:ManageBrandsDescription'
    },
    ViewDocumentLibrary: {
        id: 'B5F04EF4-A318-4781-B7EA-16502E4FD046',
        name: 'securityF:ViewDocumentLibrary',
        description: 'securityF:ViewDocumentLibraryDescription'
    },
    ManageDocumentLibrary: {
        id: '47E83493-0BC0-4148-8200-13610E9EF087',
        name: 'securityF:ManageDocumentLibrary',
        description: 'securityF:ManageDocumentLibraryDescription'
    },
    ManageGeneralDocuments: {
        id: '2B836828-2A0D-4B74-9C63-1892097AB2A1',
        name: 'securityF:ManageGeneralDocuments',
        description: 'securityF:ManageGeneralDocumentsDescription'
    },
    ManageAlerts: {
        id: '28FBCC2D-7D2E-4A56-A6DA-17B08B3D3444',
        name: 'securityF:ManageAlerts',
        description: 'securityF:ManageAlertsDescription'
    },
    ManageCodes: {
        id: '36B49774-29AF-40C1-818F-15C306197EFC',
        name: 'securityF:ManageCodes',
        description: 'securityF:ManageCodesDescription'
    },
    ManageCustomerWorkOrders: {
        id: 'FEE227A5-21FD-4301-A0CB-BC101742950F',
        name: 'securityF:ManageCustomerWorkOrders',
        description: 'securityF:ManageCustomerWorkOrdersDescription'
    },
    ManageWorkOrderPMP: {
        id: '0EA6A35F-C45E-4699-8BDA-96ED23DC22D3',
        name: 'securityF:ManageWorkOrderPMP',
        description: 'securityF:ManageWorkOrderPMPDescription'
    },
    ManageWorkOrderAdmin: {
        id: '71600CA9-6AD1-4763-857F-4676785EADB6',
        name: 'securityF:ManageWorkOrderAdmin',
        description: 'securityF:ManageWorkOrderAdminDescription'
    },
    RunReports: {
        id: 'FF7FAA1C-DF33-4237-98DA-2A8279250619',
        name: 'securityF:RunReports',
        description: 'securityF:RunReportsDescription'
    },
    // RunAuditReport: {
    //     id: '3F7939D0-1AC0-4F06-B54B-931F8E673F69',
    //     name: 'securityF:RunAuditReport',
    //     description: 'securityF:RunAuditReport'
    // },
    // RunVerificationReport: {
    //     id: '1DD9F449-3804-4868-9258-542877847FBF',
    //     name: 'securityF:RunVerificationReport',
    //     description: 'securityF:RunVerificationReport'
    // },
    // RunAGSRebalanceReport: {
    //     id: '6A5945C0-04E2-42C9-997A-2560A716EA06',
    //     name: 'securityF:RunAGSRebalanceReport',
    //     description: 'securityF:RunAGSRebalanceReportDescription'
    // },
    // RunCommissioningReport: {
    //     id: 'BD90D27B-5266-4B4B-AE30-968FA9855BD5',
    //     name: 'securityF:RunCommissioningReport',
    //     description: 'securityF:RunCommissioningReportDescription'
    // },
    AllowAllCustomers: {
        id: 'FB36F652-705E-4CAC-996F-558CC1BC11AF',
        name: 'securityF:AllowAllCustomers',
        description: 'securityF:AllowAllCustomersDescription'
    },
    RecieveContact: {
        id: 'E6491559-9664-4BF9-98C6-B55D4648F638',
        name: 'securityF:RecieveContact',
        description: 'securityF:RecieveContactDescription'
    },
    DashboardInspection: {
        id: 'D75CD19E-F89B-467E-A881-AB2FE316465C',
        name: 'securityF:DashboardInspection',
        description: 'securityF:DashboardInspectionDescription'
    },
    DashboardTAE: {
        id: '2EB141B4-692C-47FA-BDF8-EC87FE36FBFC',
        name: 'securityF:DashboardTAE',
        description: 'securityF:DashboardTAEDescription'
    },
    DashboardSmartlinks: {
        id: '90BD451F-667C-4EFF-9AD7-EF5CD4F4410D',
        name: 'securityF:DashboardSmartlinks',
        description: 'securityF:DashboardSmartlinksDescription'
    },
    DashboardPMP: {
        id: 'F0C9B006-8A6B-4B45-8454-5574522E45DE',
        name: 'securityF:DashboardPMP',
        description: 'securityF:DashboardPMPDescription'
    },
    ManageJobComments: {
        id: 'C374FD13-5E93-46E9-B0EF-E6D001850D7C',
        name: 'securityF:ManageJobComments',
        description: 'securityF:ManageJobCommentsDescription'
    },
    // RunVisitReport: {
    //     id: 'C9C6ABC6-B5F0-4D40-82F0-C1A90036AF85',
    //     name: 'securityF:RunVisitReport',
    //     description: 'securityF:RunVisitReportDescription'
    // },
    ViewCustomers: {
        id: 'DD6495E8-5EA5-4116-9951-9E3DF6C6E560',
        name: 'securityF:ViewCustomers',
        description: 'securityF:ViewCustomersDescription'
    },
    SalesParts: {
        id: '6A0C2EF5-FA7A-4056-BA21-806066719FD8',
        name: 'securityF:SalesParts',
        description: 'securityF:SalesPartsDescription'
    },
    SalesInstallation: {
        id: '9FF13CF9-658B-456D-9F16-61D26BB1B3E0',
        name: 'securityF:SalesInstallation',
        description: 'securityF:SalesInstallationDescription'
    },
    SalesService: {
        id: '65A98A0B-2427-45ED-B130-F508960CFC0C',
        name: 'securityF:SalesService',
        description: 'securityF:SalesServiceDescription'
    },
    SalesManager: {
        id: 'A14BDD5F-14FF-49E5-81F0-5AB85FFD454D',
        name: 'securityF:SalesManager',
        description: 'securityF:SalesManagerDescription'
    },
    SalesAdmin: {
        id: '7AC36557-025E-496E-94DB-E99181982FA1',
        name: 'securityF:SalesAdmin',
        description: 'securityF:SalesAdminDescription'
    },
    ManageCustomerQuotes: {
        id: 'CDD578F8-F20C-4CBD-A948-407CDB0200B5',
        name: 'securityF:ManageCustomerQuotes',
        description: 'securityF:ManageCustomerQuotesDescription'
    },
    DashboardQuote: {
        id: '8D326BA2-DA50-4B15-BC0A-3659E7348F7D',
        name: 'securityF:DashboardQuote',
        description: 'securityF:DashboardQuoteDescription'
    },
    FSECustomer: {
        id: '156E2179-D321-4F46-A883-D6646C54E362',
        name: 'securityF:FSECustomer',
        description: 'securityF:FSECustomerDescription'
    },
    ManageSuggestedParts: {
        id: '48AEC40C-27B1-4773-A1CC-3023EEABB891',
        name: 'securityF:ManageSuggestedParts',
        description: 'securityF:ManageSuggestedPartsDescription'
    },
    ViewSuggestedParts: {
        id: 'E231A44F-25E7-4C4D-8693-96E30272DBC8',
        name: 'securityF:ViewSuggestedParts',
        description: 'securityF:ViewSuggestedPartsDescription'
    },
    ManageSystemDocuments: {
        id: '2BE1DCCD-3C3E-4518-8B03-859D57AF0D90',
        name: 'securityF:ManageSystemDocuments',
        description: 'securityF:ManageSystemDocumentsDescription'
    },
    AllowAllCountries: {
        id: '64D24BBC-671C-4273-A975-0C0D9ED23F33',
        name: 'securityF:AllowAllCountries',
        description: 'securityF:AllowAllCountriesDescription'
    },
    // RunServicePlanReport: {
    //     id: '9A4BD96E-477C-4190-847B-CB4ED22D7B9B',
    //     name: 'securityF:RunServicePlanReport',
    //     description: 'securityF:RunServicePlanReportDescription'
    // },
    FSEFacilityContactEdit: {
        id: '8E08F739-3B0F-43F5-8751-B1E9BA920DDC',
        name: 'securityF:FSEFacilityContactEdit',
        description: 'securityF:FSEFacilityContactEditDescription'
    },
    ManageReports: {
        id: 'BE652BF9-D046-4F13-84F9-9AC63FCCA9A7',
        name: 'securityF:ManageReportsName',
        description: 'securityF:ManageReportsDescription'
    }
};
