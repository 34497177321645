import * as React from 'react';
import { FormUtil } from '../common/FormUtil';
import {
    FieldConfig,
    FormGenerator,
    FormGroup,
    AbstractControl
} from 'react-reactive-form';
import { jobTypeOptionsTranslated } from '../../constants/constants';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Ioption, IproductInfo } from '../../models';

// Define the type for form values
interface FormValues {
    brand?: Ioption | null;
    jobType?: Ioption | null;
    default?: Ioption | null;
}

interface IReportFilterFormProps {
    productInfo: IproductInfo;
    setFormGroup: (formValues: FormValues) => void; // Expect form values to be passed here
}

const ManageReportFilter: React.FC<IReportFilterFormProps &
    WithTranslation> = ({ t, productInfo, setFormGroup }) => {
    const formGroupRef = React.useRef<FormGroup | null>(null); // Reference for FormGroup

    // Configure the search fields for the filter form
    const searchFieldConfig = (): FieldConfig => {
        return {
            controls: {
                brand: {
                    render: FormUtil.Select,
                    meta: {
                        options: productInfo.allBrandOptions,
                        label: t('FilterBrand'),
                        colWidth: 2,
                        placeholder: t('common:searchPlaceholder'),
                        name: t('FilterBrand'),
                        defaultValue: undefined,
                        isClearable: true
                    },
                    formState: {
                        value: formGroupRef.current?.value?.brand,
                        disabled: false
                    }
                },
                jobType: {
                    render: FormUtil.Select,
                    meta: {
                        options: jobTypeOptionsTranslated(t),
                        label: t('FilterJobType'),
                        colWidth: 2,
                        placeholder: t('common:searchPlaceholder'),
                        name: t('FilterJobType'),
                        defaultValue: undefined,
                        isClearable: true
                    },
                    formState: {
                        value: formGroupRef.current?.value?.jobType,
                        disabled: false
                    }
                },
                default: {
                    render: FormUtil.Select,
                    meta: {
                        options: [
                            { value: true, label: 'True' },
                            { value: false, label: 'False' }
                        ],
                        label: t('FilterDefault'),
                        colWidth: 2,
                        name: 'default',
                        defaultValue: null,
                        isClearable: true
                    },
                    formState: {
                        value: formGroupRef.current?.value?.default,
                        disabled: false
                    }
                }
            }
        };
    };

    // Function to handle when the form is mounted and track value changes
    const handleFormMount = (form: AbstractControl) => {
        formGroupRef.current = form as FormGroup;

        // Subscribe to value changes in the form and explicitly type `values`
        formGroupRef.current?.valueChanges.subscribe((values: FormValues) => {
            setFormGroup(values); // Pass the form values to the parent component
        });
    };

    return (
        <div
            className="filter-header"
            style={{
                display: 'flex',
                gap: '1rem',
                marginBottom: '1rem',
                marginTop: '0.5rem'
            }}
        >
            <FormGenerator
                onMount={handleFormMount} // Track the form mount and its value changes
                fieldConfig={searchFieldConfig()}
            />
        </div>
    );
};

export default withTranslation('manageReportBuilder')(ManageReportFilter);
