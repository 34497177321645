/*
 * Manage Report Modal and Container
 */

import { connect, useDispatch, useSelector } from 'react-redux';
import {
    runDailyRoundsReport,
    runReport,
    uploadExternalDocument
} from '../../actions/manageReportActions';
import { jobTypesIdEnumInverse, ReportChapterType } from '../../models-enums';

import { selectJobsByID } from '../../reducers/manageJobReducer';
import { withTranslation, WithTranslation } from 'react-i18next';
import { WithCommonModal } from '../common/WithCommonModal';
import { IinitialState } from '../../reducers';
import { selectSelectedFacility } from '../../reducers/facilitiesReducer';
import { IreportExternalDocument, IreportTemplate } from '../../models';
import { FormUtil } from '../common/FormUtil';
import { map, orderBy } from 'lodash';
import moment from 'moment';
import {
    AbstractControl,
    FieldConfig,
    FormGenerator,
    FormGroup,
    GroupProps
} from 'react-reactive-form';
import * as React from 'react';
import { toastr } from 'react-redux-toastr';

import { constants } from '../../constants/constants';
import { Button, Col } from 'react-bootstrap';
import { svgIcons } from '../../constants/svgIcons';
import { beginAjaxCall, endAjaxCall } from '../../actions/ajaxStatusActions';
import { selectIsLoading } from '../../reducers/commonReducers';

interface Iprops {
    selectedTemplate: IreportTemplate | undefined;
    forDailyRounds: boolean;

    closeModal: () => void;
}

interface IdispatchProps {
    runReport: typeof runReport;
    runDailyRoundsReport: typeof runDailyRoundsReport;
    loading: boolean;
}

const ManageReportModal: React.FC<Iprops &
    IdispatchProps &
    WithTranslation> = props => {
    const { t, selectedTemplate } = props;
    const formGroupRef = React.useRef<FormGroup | null>(null);
    const dispatch = useDispatch();
    let subscription: any;

    const inputRefs = React.useRef<Record<string, HTMLInputElement | null>>({});
    const [externalDocs, setExternalDocs] = React.useState<
        IreportExternalDocument[]
    >([]);

    const selectedFacility = useSelector(selectSelectedFacility);
    const allJobs = useSelector(selectJobsByID);

    const jobOptions = React.useMemo(() => {
        const allJobsForFacility = Object.values(allJobs).filter(
            x => x.facilityID === selectedFacility.id
        );
        const filteredJobs = allJobsForFacility.filter(
            x => x.jobTypeID === selectedTemplate?.jobTypeID
        );
        const jobsWithName = map(filteredJobs, job => {
            const startDate = moment
                .utc(job.startDate)
                .local(true)
                .format('DD-MMM-YY');
            const jobType = t(
                `nsJob:${
                    jobTypesIdEnumInverse[
                        job.jobTypeID as keyof typeof jobTypesIdEnumInverse
                    ]
                }`
            );
            const name = `${startDate} ${jobType} ${job.jobNumber} (${job.status})`;

            return { ...job, name };
        });

        return FormUtil.convertToOptions(
            orderBy(
                jobsWithName,
                res => moment.utc(res.startDate).unix(),
                'desc'
            )
        );
    }, [selectedFacility.id, selectedTemplate?.id]);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file && file.size < 100000000) {
            dispatch(beginAjaxCall());
            uploadExternalDocument(event?.target?.name, file)
                .then((result: IreportExternalDocument) => {
                    setExternalDocs([
                        ...externalDocs,
                        {
                            reportTemplateChapterID:
                                result?.reportTemplateChapterID,
                            mimeType: file.type,
                            fileName: result.fileName
                        }
                    ]);
                })
                .finally(() => {
                    dispatch(endAjaxCall());
                });
        } else {
            toastr.error(
                props.t('manageDocument:fileSizeWarning'),
                '',
                constants.toastrError
            );
            return;
        }
    };

    React.useEffect(() => {
        console.log('externalDocs', externalDocs);
    }, [externalDocs]);

    const buildFieldConfig = React.useMemo(() => {
        const disabled = false;
        const jobID = null;

        // Field config to configure form
        const jobSelectControl = {
            jobID: {
                render: FormUtil.Select,
                meta: {
                    options: jobOptions,
                    label: 'common:job',
                    colWidth: 12,
                    placeholder: 'jobPlaceholder',
                    name: 'job',
                    disableSort: true
                },
                options: {
                    validators: [FormUtil.validators.requiredWithTrim]
                },
                formState: {
                    value: jobID,
                    disabled
                }
            }
        } as { [key: string]: GroupProps };

        if (props.forDailyRounds) {
            return {
                controls: {
                    startDate: {
                        render: FormUtil.Datetime,
                        meta: {
                            label: 'startDate',
                            colWidth: 12,
                            name: 'startDate'
                        },
                        options: {
                            validators: [
                                FormUtil.validators.requiredWithTrim,
                                FormUtil.validators.isValidMoment
                            ]
                        },
                        formState: {
                            value: '',
                            disabled
                        }
                    },
                    endDate: {
                        render: FormUtil.Datetime,
                        meta: {
                            label: 'endDate',
                            colWidth: 12,
                            name: 'endDate'
                        },
                        options: {
                            validators: [
                                FormUtil.validators.requiredWithTrim,
                                FormUtil.validators.isValidMoment
                            ]
                        },
                        formState: {
                            value: '',
                            disabled
                        }
                    }
                }
            };
        } else {
            return { controls: { ...jobSelectControl } };
        }
    }, [props.forDailyRounds, selectedTemplate, jobOptions, externalDocs]);

    const [fieldConfig, setFieldConfig] = React.useState<FieldConfig>(
        buildFieldConfig
    );

    React.useEffect(() => {
        setFieldConfig(FormUtil.translateForm(buildFieldConfig, props.t));

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, []);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (formGroupRef?.current?.status === 'INVALID') {
            formGroupRef?.current?.markAsSubmitted();
            toastr.error(
                props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        if (props.forDailyRounds) {
            props.runDailyRoundsReport(
                formGroupRef?.current?.value,
                selectedFacility?.id,
                false
            );
        } else {
            props.runReport(
                selectedTemplate?.id || '',
                formGroupRef?.current?.value.jobID.value,
                false,
                externalDocs
            );
        }

        props.closeModal();
    };

    const handleRunAndSendExternal = (e: any) => {
        e.preventDefault();
        if (formGroupRef?.current?.status === 'INVALID') {
            formGroupRef?.current?.markAsSubmitted();
            toastr.error(
                props.t('toastMessage:invalidFormSubmission'),
                '',
                constants.toastrError
            );
            return;
        }
        if (props.forDailyRounds) {
            props.runDailyRoundsReport(
                formGroupRef?.current?.value,
                selectedFacility?.id,
                true
            );
        } else {
            props.runReport(
                selectedTemplate?.id || '',
                formGroupRef?.current?.value.jobID.value,
                true,
                externalDocs
            );
        }

        props.closeModal();
    };

    const setForm = (form: AbstractControl) => {
        formGroupRef.current = form as FormGroup;
    };

    const clearDocument = (reportTemplateChapterID: string) => {
        const updatedDocs = externalDocs.filter(
            x => x.reportTemplateChapterID !== reportTemplateChapterID
        );
        setExternalDocs(updatedDocs);

        if (inputRefs.current[reportTemplateChapterID]) {
            inputRefs.current[reportTemplateChapterID]!.value = ''; // Clear the value
        }
    };

    const formClassName = `clearfix job-form beacon-form`;

    return (
        <form className={formClassName}>
            <FormGenerator onMount={setForm} fieldConfig={fieldConfig} />
            <div className="manage-report-modal">
                {selectedTemplate?.reportTemplateChapters.map(chapter => {
                    if (
                        chapter.reportChapter?.chapterType ===
                        ReportChapterType.ExternalDocument
                    ) {
                        const externalDoc = externalDocs.find(
                            x => x.reportTemplateChapterID === chapter.id
                        );
                        return (
                            <div
                                key={chapter?.id}
                                className="external-doc-section"
                            >
                                <h5>Add External Document</h5>

                                <div className="file-container">
                                    <input
                                        type="file"
                                        name={chapter?.id}
                                        ref={el =>
                                            (inputRefs.current[
                                                chapter?.id!
                                            ] = el)
                                        }
                                        accept="application/pdf, image/jpeg, image/jpg, image/png, image/tiff, image/bmp"
                                        onChange={onFileChange}
                                    />
                                    {externalDoc && (
                                        <span
                                            onClick={() =>
                                                clearDocument(chapter?.id!)
                                            }
                                        >
                                            {svgIcons['closeMenu']}
                                        </span>
                                    )}
                                </div>
                            </div>
                        );
                    }
                })}
            </div>

            <Col xs={12} className="form-buttons text-right">
                <Button
                    bsStyle="default"
                    type="button"
                    className="pull-left"
                    onClick={props.closeModal}
                >
                    {t('cancel')}
                </Button>
                <Button
                    bsStyle={'warning'}
                    disabled={props.loading}
                    style={{ marginRight: '5px', marginLeft: '5px' }}
                    onClick={(e: React.MouseEvent<Button>) =>
                        handleRunAndSendExternal(e)
                    }
                >
                    {t('runAndSendExternal')}
                </Button>
                <Button
                    bsStyle={'warning'}
                    disabled={props.loading}
                    onClick={(e: React.MouseEvent<Button>) => handleSubmit(e)}
                >
                    {t('save')}
                </Button>
            </Col>
        </form>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    return {
        loading: selectIsLoading(state)
    };
};

export default withTranslation('manageReport')(
    connect(mapStateToProps, {
        runReport,
        runDailyRoundsReport
    })(WithCommonModal(ManageReportModal))
);
