import { toastr } from 'react-redux-toastr';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
    ItableFiltersParams,
    Ireport,
    IdefaultReport,
    ThunkResult,
    IreportTemplate,
    IreportTemplateChapter,
    IreportExternalDocument
} from '../models';
import { beginAjaxCall, endAjaxCall } from './ajaxStatusActions';
import API from '../constants/apiEndpoints';
import { constants } from '../constants/constants';
import * as types from './actionTypes';
import { msalFetch } from '../components/auth/Auth-Utils';
import { reportTypeEnum } from '../models-enums';
import moment from 'moment';

export function getDefaultReports(): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());
        const axiosOptions: AxiosRequestConfig = {
            method: 'get'
        };

        const url = API.GET.report.defaults;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                if (!data.data) {
                    throw new Error('missing data');
                } else {
                    dispatch({
                        type: types.REPORT_MANAGE_GET_DEFAULT_SUCCESS,
                        reports: data.data
                    });
                    return data;
                }
            })
            .catch((error: any) => {
                dispatch({
                    type: types.REPORT_MANAGE_GET_DEFAULT_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'get default reports');
                console.error(error);
            })
            .finally(() => dispatch(endAjaxCall()));
    };
}

export function updateReport(
    selectedDefaultReport: IdefaultReport,
    formValues: any
): ThunkResult<any> {
    return (dispatch, getState) => {
        // dispatch(beginAjaxCall());
        const { jobID, coverLetter, headerLogoPath = '' } = formValues;
        const report: Ireport = {
            jobID,
            reportType: selectedDefaultReport.reportType,
            coverLetter,
            headerLogoPath
        };
        dispatch({
            type: types.REPORT_UPDATE,
            report
        });
        // return axios
        //   .post(`${API.POST.job.update}`, { job, users })
        //   .then(data => {
        //     if (!data.data) {
        //       throw undefined;
        //     } else {
        // dispatch({
        //   type: types.REPORT_UPDATE_SUCCESS,
        //   job: data.data
        // });

        //       // toastr.success('Success', 'Saved job', constants.toastrSuccess);
        //       return data;
        //     }
        //   })
        //   .catch((error: any) => {
        //     dispatch({ type: types.REPORT_UPDATE_FAILED, error, axiosOptions });
        //     constants.handleError(error, 'update job');
        //     console.error(error);
        //   });
    };
}

export function runDailyRoundsReport(
    formValues: any,
    facilityID: string,
    sendToExternal: boolean
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            params: {
                facilityID: facilityID,
                sendToExternal: sendToExternal,
                startDate: formValues.startDate
                    ? moment.utc(formValues.startDate).toISOString()
                    : '',
                endDate: formValues.endDate
                    ? moment.utc(formValues.endDate).toISOString()
                    : ''
            },
            data: {}
        };

        const url = API.POST.report.runDailyRounds;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.REPORT_ADD_SUCCESS
                });

                dispatch({ type: types.TOGGLE_MODAL_EDIT_REPORT });
                toastr.success(
                    'Success',
                    'Your report is being generated and will be emailed to you as soon as it is ready.',
                    { ...constants.toastrSuccess, timeOut: 5000 }
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.REPORT_ADD_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'run report');
                console.error(error);
            });
    };
}

/*
 * run a new report
 */
export function runReport(
    reportTemplateID: string,
    jobID: string,
    sendToExternal: boolean,
    requestData: IreportExternalDocument[]
): ThunkResult<any> {
    return (dispatch, getState) => {
        dispatch(beginAjaxCall());

        const axiosOptions: AxiosRequestConfig = {
            method: 'post',
            data: { externalDocuments: requestData },
            params: {
                reportTemplateID,
                jobID,
                sendToExternal
            }
        };

        const url = API.POST.report.run;
        return msalFetch(url, axiosOptions)
            .then((data: AxiosResponse<any>) => {
                dispatch({
                    type: types.REPORT_ADD_SUCCESS
                });

                dispatch({ type: types.TOGGLE_MODAL_EDIT_REPORT });
                toastr.success(
                    'Success',
                    'Your report is being generated and will be emailed to you as soon as it is ready.',
                    { ...constants.toastrSuccess, timeOut: 5000 }
                );
            })
            .catch((error: any) => {
                dispatch({
                    type: types.REPORT_ADD_FAILED,
                    error,
                    axiosOptions
                });
                constants.handleError(error, 'run report');
                console.error(error);
            });
    };
}

export function uploadExternalDocument(
    reportTemplateChapterID: string,
    selectedFile: any
): Promise<any> {
    const formData = new FormData();
    formData.append('file', selectedFile);

    const headers = { 'content-type': 'multipart/form-data' };
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: formData,
        params: {
            reportTemplateChapterID
        },
        headers
    };

    const url = API.POST.report.uploadExternalDocument;
    return msalFetch(url, axiosOptions)
        .then((data: AxiosResponse<any>) => {
            console.log('data', data);

            return data.data;
        })
        .catch((error: any) => {
            console.error(error);
            toastr.error('Error', 'Failed to upload external document.');
        });
}

// Get All Report Templates
export async function getAllReportTemplates(): Promise<IreportTemplate[]> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'get'
    };

    const url = API.GET.report.templates;

    try {
        const data: AxiosResponse<any> = await msalFetch(url, axiosOptions);
        return data.data; // Return the report templates directly
    } catch (error) {
        console.error('Error fetching report templates:', error);
        throw error; // Optionally rethrow the error
    }
}

// Create Report Template
export async function createReportTemplate(
    newTemplate: IreportTemplate
): Promise<any> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: newTemplate
    };

    const url = API.POST.report.templates;

    try {
        // Attempt to create the report template
        const response: AxiosResponse<any> = await msalFetch(url, axiosOptions);

        // Show success notification on creation
        toastr.success('Report template created successfully.', 'Success');

        // Return response data if successful
        return response.data;
    } catch (error) {
        // Cast error to `any` to access response details safely
        const err = error as any;

        // Check if the error is a 400 status code and display the specific message
        if (err.response?.status === 400) {
            toastr.error(
                'A default report template with the same Brand and Job Type already exists.',
                'Error'
            );
        } else {
            // Display a general error message if it's a different error
            const errorMessage =
                err.response?.data?.message ||
                'Failed to create report template.';
            toastr.error(errorMessage, 'Error');
        }

        // Log error details for debugging
        console.error('Failed to create report template:', error);

        // Re-throw the error for additional handling if needed
        throw error;
    }
}

// Update Report Template
export async function updateReportTemplate(
    id: string, // Pass the template ID
    updatedTemplate: any // The updated template data
): Promise<any> {
    // Replace {id} placeholder with the actual id
    const url = API.PUT.report.templates.replace('{id}', id);

    const axiosOptions: AxiosRequestConfig = {
        method: 'put',
        data: updatedTemplate
    };

    try {
        // Pass the URL as the first argument and axiosOptions as the second
        const response: AxiosResponse<any> = await msalFetch(url, axiosOptions);
        toastr.success('Success', 'Report template updated successfully.');
        return response.data;
    } catch (error) {
        // More detailed error handling for better user feedback
        let errorMessage = 'Failed to update report template.';

        // Check for BadRequest and specific error message
        if (error.response?.status === 400) {
            errorMessage =
                error.response?.data ||
                'A default report template with the same Brand and Job Type already exists.';
        }

        toastr.error('Error', errorMessage);
        console.error(error);
        throw error;
    }
}

// Delete Report Template
export async function deleteReportTemplate(id: string): Promise<void> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'delete'
    };

    // Replace {id} in the URL with the actual id
    const url = API.DELETE.report.templates.replace('{id}', id);

    try {
        await msalFetch(url, axiosOptions);
        toastr.success('Success', 'Report template deleted successfully.');
    } catch (error) {
        console.error(error);
        toastr.error('Error', 'Failed to delete report template.');
        throw error;
    }
}

// Get All Chapters
export async function getAllChapters(): Promise<any[]> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'get'
    };

    const url = API.GET.report.chapters;

    try {
        const data: AxiosResponse<any> = await msalFetch(url, axiosOptions);
        return data.data;
    } catch (error) {
        console.error(error);
        toastr.error('Error', 'Failed to fetch chapters.');
        throw error;
    }
}

// Update Chapter
export async function updateChapter(
    id: string,
    updatedChapter: any
): Promise<any> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'put',
        data: updatedChapter
    };

    const url = API.PUT.report.chapters;

    try {
        const data: AxiosResponse<any> = await msalFetch(url, axiosOptions);
        toastr.success('Success', 'Chapter updated successfully.');
        return data.data;
    } catch (error) {
        console.error(error);
        toastr.error('Error', 'Failed to update chapter.');
        throw error;
    }
}

// Delete Chapter
export async function deleteChapter(id: string): Promise<void> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'delete'
    };

    const url = API.DELETE.report.chapters;

    try {
        await msalFetch(url, axiosOptions);
        toastr.success('Success', 'Chapter deleted successfully.');
    } catch (error) {
        console.error(error);
        toastr.error('Error', 'Failed to delete chapter.');
        throw error;
    }
}

// Get All Report Template Chapters
export async function getAllReportTemplateChapters(
    templateId: string
): Promise<any[]> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'get'
    };

    const url = `${API.GET.report.templateChapters}?templateId=${templateId}`;

    try {
        const data: AxiosResponse<any> = await msalFetch(url, axiosOptions);

        if (data && data.data) {
            return data.data; // Return the fetched chapters
        } else {
            return []; // Return an empty array if no chapters
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            // If it's a 404 error, return an empty array and do not show a toast
            return []; // No chapters found, just return an empty array
        }

        console.error(error);
        toastr.error('Error', 'Failed to fetch template chapters.');
        return []; // Return an empty array on error to prevent breaking the UI
    }
}

// Create Report Template Chapter
export async function createReportTemplateChapter(
    newTemplateChapter: IreportTemplateChapter // Expect an object of this type
): Promise<any> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'post',
        data: newTemplateChapter // Send the data in the correct format
    };

    const url = API.POST.report.templateChapters; // API endpoint for creating the chapter

    try {
        // Make the API call using your msalFetch function
        const data: AxiosResponse<any> = await msalFetch(url, axiosOptions);

        // Show a success message
        toastr.success('Success', 'Template chapter created successfully.');

        // Return the response data
        return data.data;
    } catch (error) {
        // Handle errors
        console.error(error);
        toastr.error('Error', 'Failed to create template chapter.');
        throw error;
    }
}

// Update Report Template Chapters (bulk update)
export async function updateReportTemplateChapter(
    updatedTemplateChapters: IreportTemplateChapter[] // Accepting an array now
): Promise<any> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'put',
        data: updatedTemplateChapters // Send array of updated chapters
    };

    const url = API.PUT.report.templateChapters; // Ensure this URL supports bulk update

    try {
        const response: AxiosResponse<any> = await msalFetch(url, axiosOptions);
        toastr.success('Success', 'Template chapters updated successfully.');
        return response.data;
    } catch (error) {
        console.error(error);
        toastr.error('Error', 'Failed to update template chapters.');
        throw error;
    }
}

// Delete Report Template Chapter
export async function deleteReportTemplateChapter(
    chapter: IreportTemplateChapter
): Promise<void> {
    const axiosOptions: AxiosRequestConfig = {
        method: 'delete',
        data: chapter // Pass the entire chapter object in the request body
    };

    const url = API.DELETE.report.templateChapters; // Use static URL

    try {
        await msalFetch(url, axiosOptions); // Call the API to delete the chapter
        toastr.success('Success', 'Template chapter deleted successfully.');
    } catch (error) {
        console.error(error);
        toastr.error('Error', 'Failed to delete template chapter.');
        throw error;
    }
}

export const toggleEditReportModal = () => ({
    type: types.TOGGLE_MODAL_EDIT_REPORT
});
export const setTableFilter = (filters: ItableFiltersParams) => ({
    type: types.SET_TABLE_FILTER_MANAGE_REPORT,
    filters
});

export const setSelectedReport = (report: Ireport) => ({
    type: types.SET_SELECTED_REPORT,
    report
});

export const setSelectedDefaultReport = (defaultReportID: string) => ({
    type: types.SET_SELECTED_DEFAULT_REPORT_ID,
    defaultReportID
});
