import * as React from 'react';

import { withTranslation, WithTranslation } from 'react-i18next';

import { Ifacility, IreportTemplate, Itile } from '../../models';
import ReactTable, { Column, FinalState, RowInfo } from 'react-table';
import { emptyTile, initialFacility } from '../../reducers/initialState';
import {
    getAllReportTemplates,
    getDefaultReports,
    setSelectedDefaultReport,
    setSelectedReport,
    setTableFilter,
    toggleEditReportModal
} from '../../actions/manageReportActions';

import { Banner } from '../common/Banner';
/*
 * Manage Reports
 */
import ManageReportModal from './ManageReportModal';
import { RouteComponentProps } from 'react-router-dom';
import { SelectFacilityContainer } from '../common/SelectFacilityContainer';
import { TableUtil } from '../common/TableUtil';
import { closeAllModals } from '../../actions/commonActions';
import { connect, useSelector } from 'react-redux';
import { constants } from '../../constants/constants';
import { getJobsByFacility } from '../../actions/manageJobActions';
import { getCanAccessAllCustomers } from '../../reducers/userReducer';
import { selectIsLoading } from '../../reducers/commonReducers';
import { IinitialState } from '../../reducers';
import { jobTypesIdEnumInverse } from '../../models-enums';
import { getProductInfo } from '../../reducers/productInfoReducer';

interface RowInfoReport extends RowInfo {
    original: IreportTemplate;
}

interface Iprops extends RouteComponentProps<any> {
    // Add your regular properties here
    loading: boolean;
}

interface IdispatchProps {
    // Add your dispatcher properties here
    setSelectedReport: typeof setSelectedReport;
    setSelectedDefaultReport: typeof setSelectedDefaultReport;
    getJobsByFacility: typeof getJobsByFacility;
    selectedFacility: Ifacility;
    canAccessAllCustomers: boolean;
}

const ManageReport: React.FC<Iprops &
    IdispatchProps &
    WithTranslation> = props => {
    const { t } = props;
    const [columns, setColumns] = React.useState<any[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<any>(null);
    const [currentTile, setCurrentTile] = React.useState<Itile>(emptyTile);
    const [selectedTemplate, setSelectedTemplate] = React.useState<
        IreportTemplate | undefined
    >(undefined);
    const productInfo = useSelector(getProductInfo);
    const [showManageReportModal, setShowManageReportModal] = React.useState<
        boolean
    >(false);

    const [reportTemplates, setReportTemplates] = React.useState<
        IreportTemplate[]
    >([]);

    // Function to fetch templates
    const fetchTemplates = async () => {
        const fetchedTemplates = await getAllReportTemplates();
        setReportTemplates(fetchedTemplates);
    };

    React.useEffect(() => {
        setCurrentTile(constants.getTileByURL(props.location.pathname));
        setColumns(
            TableUtil.translateHeaders(
                [
                    {
                        Header: 'Template Name',
                        accessor: 'templateName' // Ensure this matches the key in your data
                    },
                    {
                        id: 'jobType',
                        Header: 'Job Type',
                        accessor: (row: IreportTemplate) => {
                            return props.t(
                                `nsJob:${
                                    jobTypesIdEnumInverse[
                                        row.jobTypeID as keyof typeof jobTypesIdEnumInverse
                                    ]
                                }`
                            );
                        }
                    },
                    {
                        id: 'brand',
                        Header: 'Brand',
                        accessor: (row: IreportTemplate) => {
                            return productInfo?.allBrands[row.brandID]?.name;
                        }
                    },
                    {
                        Header: 'Is Default',
                        accessor: 'isDefault', // Ensure this matches the key in your data
                        Cell: ({ value }: { value: boolean }) => (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <span>{value ? 'Yes' : 'No'}</span>
                            </div>
                        )
                    }
                ],
                t
            )
        );

        fetchTemplates();
        props.getJobsByFacility();
    }, []);

    React.useEffect(() => {
        if (!showManageReportModal) {
            setSelectedRow(null);
        }
    }, [showManageReportModal]);

    React.useEffect(() => {
        props.getJobsByFacility();
    }, [props.selectedFacility.id]);

    /*
     * (reusable)
     * set the row color after a user selects it
     */
    const getTrProps = (state: FinalState, rowInfo: RowInfo | undefined) => {
        if (rowInfo) {
            return {
                style: {
                    background:
                        rowInfo.index === selectedRow
                            ? constants.colors[
                                  `${currentTile.color}Tr` as keyof typeof constants.colors
                              ]
                            : ''
                }
            };
        } else {
            return {};
        }
    };
    /*
     * (reusable)
     * get the next or previous page of data.  the table is 0 indexed but the API is not
     */
    const onPageChange = (page: number) => {
        // const newPage = page + 1;
        // props.setTableFilter({ page: newPage });
    };

    const getTdProps = (
        fState: FinalState,
        rowInfo: RowInfoReport | undefined,
        column: Column | undefined,
        instance: any
    ) => {
        if (rowInfo) {
            return {
                onClick: () => {
                    setSelectedRow(rowInfo.index);
                    setSelectedTemplate(rowInfo.original);
                    //props.setSelectedDefaultReport(rowInfo.original.id);
                    setShowManageReportModal(true);
                }
            };
        } else {
            return {};
        }
    };

    return (
        <div className="manage-job">
            <Banner
                title={t('bannerTitle')}
                img={currentTile.srcBanner}
                color={currentTile.color}
            >
                <SelectFacilityContainer t={t} classNameOverride={''} />
            </Banner>
            <ReactTable
                data={reportTemplates}
                columns={columns}
                getTrProps={getTrProps}
                pageSize={
                    reportTemplates.length >= 10 ? reportTemplates.length : 10
                }
                showPageSizeOptions={false}
                className={`beacon-table -highlight ${currentTile.color}`}
                previousText={t('common:previous')}
                nextText={t('common:next')}
                onPageChange={onPageChange}
                sortable={false}
                noDataText={t('common:noDataText')}
                resizable={false}
                getTdProps={getTdProps}
            />
            <ManageReportModal
                selectedTemplate={selectedTemplate}
                forDailyRounds={false}
                className="first-modal"
                show={showManageReportModal}
                title={
                    selectedTemplate
                        ? selectedTemplate.templateName
                        : 'Daily Rounds Report'
                }
                closeModal={() => setShowManageReportModal(false)}
                onHide={() => setShowManageReportModal(false)}
            />
        </div>
    );
};

const mapStateToProps = (state: IinitialState, ownProps: Iprops) => {
    const selectedFacility =
        state.facilities[state.selectedFacilityID] || initialFacility;
    const canAccessAllCustomers = getCanAccessAllCustomers(state.user);

    return {
        user: state.user,
        loading: selectIsLoading(state),
        selectedFacility,
        canAccessAllCustomers
    };
};
export default withTranslation('manageReport')(
    connect(mapStateToProps, {
        getDefaultReports,
        setTableFilter,
        setSelectedReport,
        setSelectedDefaultReport,
        getJobsByFacility
    })(ManageReport)
);
