// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
    IWorkOrder,
    IanalyticsInspection,
    IanalyticsSmartlink,
    IanalyticsTAE,
    IcloudDocument,
    Iconfig,
    IdeficiencyCode,
    Ifacility,
    IfacilityContact,
    IfacilityContract,
    IinstallBase,
    IinstallBaseHistory,
    Ijob,
    IjobWorkOrder,
    Ilead,
    IleadActivity,
    IleadInstallBase,
    ImeasurementPointResult,
    Ipart,
    Iphoto,
    IpreventativeMaintenanceChecklist,
    Iproduct,
    IproductQueueObject,
    Iquote,
    IsuggestedPart,
    Itae,
    Itile,
    Iuser
} from '../models';
import {
    dataFormatEnum,
    dataUnitEnum,
    leadActivityTypesEnum,
    leadRankingEnum,
    leadStatusEnum,
    productCommunicationMethodEnum,
    userTypeEnum,
    widgetsEnum
} from '../models-enums';

export const initialOption = { value: '', label: '' };
export const initialTableFilters = { search: '', page: 1 };
export const initialTableFiltersFrontEndPaging = { search: '', page: 0 };

export const initialFacilityContract: IfacilityContract = {
    facilityID: '',
    id: '',
    isDeleted: false
};

export const initialFacility: Ifacility = {
    id: '',
    name: '',
    name2: '',
    address: '',
    address2: '',
    city: '',
    countryID: '',
    state: '',
    postalCode: '',
    buildings: [],
    isDeleted: false,
    standardID: '',
    sapFacilityNumber: '',
    preventativeMaintenanceEnabled: false,
    managedByID: '',
    mgpsSite: false,
    authorizedPerson: '',
    isApproved: false
};

// const initialMainCategory = {
//   id: '',
//   name: '',
//   isDeleted: false
// };
// const initialSubcategory: Isubcategory = {
//   mainCategoryID: '',
//   id: '',
//   name: '',
//   mainCategory: initialMainCategory,
//   isDeleted: false
// };

export const initialConfig: Iconfig = {
    isMobile: false
};

export const initialProduct: Iproduct = {
    id: '',
    name: '',
    sku: '',
    description: '',
    imagePath: '',
    subcategoryID: '',
    brandID: '',
    productTypeID: '',
    powerID: '',
    origin: '',
    notes: '',
    systemSizeID: '',
    installBases: [],
    isDeleted: false,
    isApproved: false,
    quantity: 1,
    mergedProductID: '',
    isCompliant: true,
    hasSpares: true,
    isActive: true,
    communicationMethod: productCommunicationMethodEnum.none,
    links: [],
    isFinalProduct: true,
    productStandards: []
};
export const initialInstallBase: IinstallBase = {
    id: '',
    productID: '',
    facilityID: '',
    isDeleted: false,
    measurementPointListResultStatus: 0,
    workOrderResultStatus: 1,
    visibleStatus: 0,
    latestMeasurementPointListResultID: '',
    latestWorkOrderID: '',
    lastQuarterlyMaintenanceDate: '',
    lastSixMonthMaintenanceDate: '',
    lastYearlyMaintenanceDate: '',
    lastTwoYearMaintenanceDate: '',
    lastThreeYearMaintenanceDate: '',
    lastFiveYearMaintenanceDate: ''
};

export const initialLoc = {
    id: '',
    name: '',
    rooms: [],
    floorID: '',
    isDeleted: false
};
export const initialContact: IfacilityContact = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    phone: '',
    mobile: '',
    enableNotifications: false,
    smartlinkNotifications: false,
    isDeleted: false,
    contactFacilities: [],
    source: 0 // MMG
};
export const initialBuilding = {
    id: '',
    name: '',
    floors: [],
    facilityID: '',
    isDeleted: false
};
export const initialFloor = {
    id: '',
    name: '',
    buildingID: '',
    locations: [],
    isDeleted: false
};

export const initialRoom = {
    id: '',
    name: '',
    locationID: '',
    isDeleted: false
};
export const initialMeasurementPointResultAnswer = {
    measurementPointID: ''
};
export const initialMeasurementPointList = {
    id: '',
    measurementPointTabs: [],
    mainCategoryID: '',
    standardID: '',
    type: 1,
    testProcedures: '',
    isDeleted: false
};
export const initialMeasurementPointTab = {
    id: '',
    name: 'Default Tab',
    measurementPoints: {},
    order: 0,
    isDeleted: false
};
export const initialMeasurementPoint = {
    id: '',
    type: 1,
    label: '',
    order: 0,
    isDeleted: false,
    isRequired: true,
    showInReport: true,
    allowNotes: true,
    defaultText: '',
    generateLead: true
};
export const initialMeasurmentPointResult: ImeasurementPointResult = {
    id: '',
    status: 0,
    jobID: '',
    notes: '',
    installBaseID: '',
    isDeleted: false,
    measurementPointListID: '',
    measurementPointAnswers: [],
    compiledNotes: '',
    manualOverride: false,
    installBaseHistory: []
};

export const initialInstallBaseHistory: IinstallBaseHistory[] = [
    {
        id: '',
        date: new Date(),
        notes: '',
        jobType: '',
        status: 0,
        workOrderId: ''
    }
];

export const initialDefaultReport = {
    id: '',
    reportType: 1,
    defaultCoverLetter: 'testing cover letter'
};
export const initialReport = {
    jobID: '123',
    reportType: 1,
    coverLetter: 'testing cover letter',
    headerLogoPath: ''
};

export const initialJob: Ijob = {
    id: '',
    facilityID: '',
    assignedUserID: '',
    jobTypeID: '',
    startDate: '',
    endDate: '',
    status: 'New',
    userJobs: [],
    jobNumber: '',
    fseNotes: '',
    isDeleted: false
};

export const initialLead: Ilead = {
    leadInstallBases: [],
    id: '',
    contactID: '',
    type: 0,
    notes: '',
    createDate: '',
    facilityID: '',
    productID: '',
    isDeleted: false,
    leadActivities: [],
    ranking: leadRankingEnum.High,
    status: leadStatusEnum.Lost,
    updateDate: '',
    quoteID: '',
    partHtml: '',
    internalComments: ''
};

export const initialLeadPopulated = {
    ...initialLead,
    leadUsers: []
};

export const initialLeadUser = {
    id: '',
    leadID: '',
    userID: '',
    assignedDueToMissingCoverage: false,
    isDeleted: false
};

export const initialQuote: Iquote = {
    id: '',
    leads: [],
    comments: '',
    quoteNumber: '1',
    revision: '',
    poNumber: '',
    signatureUrl: '',
    authorizedName: '',
    status: 0,
    isDeleted: false,
    cloudDocumentID: ''
};

export const initialCloudDocument: IcloudDocument = {
    id: '',
    isDeleted: false,
    facilityID: '',
    filename: '',
    fileSize: 0,
    mimeType: '',
    url: '',
    container: '',
    key: '',
    version: '',
    name: '',
    cloudDocumentTypeID: '',
    documentID: '',
    documentDate: '',
    countryID: '',
    isSystem: false
};

export const initialLeadInstallBase: IleadInstallBase = {
    installBaseID: '',
    id: '',
    leadID: '',
    isDeleted: false
};

export const initialJobWorkOrder: IjobWorkOrder = {
    id: '',
    jobID: '',
    workOrderID: '',
    isDeleted: false
};

export const initialBrand = {
    id: '',
    name: '',
    code: '',
    isDeleted: false,
    isManufacturer: true
};

export const initialDocument = {
    id: '',
    name: '',
    facilityID: '',
    filename: '',
    fileSize: 0,
    mimeType: '',
    url: '',
    container: '',
    key: '',
    facility: initialFacility,
    version: '',
    isDeleted: false,
    cloudDocumentTypeID: '',
    documentDate: '',
    documentID: '',
    countryID: '',
    isSystem: false
};

export const initialDocumentType = {
    id: '',
    name: ''
};

export const initialAlert = {
    id: '',
    title: '',
    text: '',
    type: '',
    imageUrl: '',
    expirationDate: ''
};

export const initialPart: Ipart = {
    id: '',
    isDeleted: false,
    description: '',
    number: '',
    installationTime: 0,
    isActive: false,
    measurement: '',
    origin: ''
};

export const initialJobPart = {
    jobID: '',
    isDeleted: false,
    estimated: 0,
    used: 0,
    lot: '',
    id: '',
    partID: ''
};
export const initialJobPartPopulated = {
    ...initialJobPart,
    part: initialPart
};

export const initialWorkOrder: IWorkOrder = {
    id: '',
    activityDescription: '',
    closingNotes: '',
    installBaseID: '',
    notes: '',
    partsDescription: '',
    priority: 3,
    status: 1,
    type: 1,
    vendor: 3,
    number: '',
    isDeleted: false,
    productName: '',
    parts: [],
    sapBusinessIndicator: '',
    userID: '',
    source: 0
};

export const initialPreventativeMaintenanceChecklist: IpreventativeMaintenanceChecklist = {
    id: '',
    name: '',
    isDeleted: false,
    preventativeMaintenancePlanID: '',
    scheduleInterval: 0,
    preventativeMaintenancePoints: []
};

export const initialUserAlert = {
    data: {},
    showUserAlertModal: false
};

/* ********************   DESKTOP ONLY  ***************/
/*

/*
*  TRAINING initial state
*/
export const initialQuiz = {
    id: '',
    name: '',
    imagePath: '',
    isComplete: false,
    videoPath: '',
    instructions: '',
    lessonID: '',
    isTimed: false,
    questions: []
};
export const intialQuizAnswer = {
    questionID: '',
    answer: '',
    isCorrect: false
};
export const initialQuizAnswers = { show: false, quizAnswers: {} };

export const initialCourse = {
    id: '',
    name: '',
    description: ''
};
export const initialLesson = {
    id: '',
    name: '',
    description: '',
    courseID: '',
    imagePath: '',
    order: 0,
    primaryVideoPath: '',
    slideshowPath: '',
    courseLessons: [],
    cost: 0,
    isProtected: false
};
export const initialUser: Iuser = {
    email: '',
    securityFunctions: [],
    userCountries: [],
    first: '',
    last: '',
    position: '',
    phone: '',
    id: '',
    type: userTypeEnum.Hospital,
    facilities: [],
    hasTeamMembers: false,
    isActive: true,
    countryID: '',
    isDeleted: false,
    widgets: `${widgetsEnum.inspectionStatus},${widgetsEnum.preventativeMaintenance},${widgetsEnum.tae},${widgetsEnum.quotes}`,
    salesManagerID: '',
    optInGDPR: true,
    userStandards: []
};

export const initialUserQueue = {
    id: '',
    user: initialUser
};

export const initialProductQueueObject: IproductQueueObject = {
    id: '',
    productID: '',
    creator: initialUser,
    product: initialProduct,
    isDeleted: false
};

// not used currently
export const initialTrainingProgress = {
    id: '',
    lessonID: '',
    userID: '',
    currentTime: 0, // the current time of the video as reported from vimeo
    percentageComplete: 0, // the current percent complete as reported  by vimeo
    totalTime: 0, // the total time (or duration) in second of the video, from vimeo
    timeSpent: 0,
    isComplete: false
};

// Dashboard Analytics
export const initialAnalyticsInspection: IanalyticsInspection = {
    mainCategoryID: '',
    mainCategoryName: '',
    jobDate: '',
    data: [],
    total: 0
};
export const initialAnalyticsQuote = {
    total: 0,
    new: 0,
    approved: 0,
    inProgress: 0,
    rejected: 0,
    unquotedLeads: 0
};

export const initialAnalyticsTAE: IanalyticsTAE = {
    photoUrl: '',
    coAlarm: false,
    lagAlarm: false,
    leftBankPressureBar: 0,
    rightBankPressureBar: 0,
    deliveryPressureBar: 0,
    reservePressureBar: 0,
    reservePressurePSI: 0,
    leftBankPressureKPA: 0,
    rightBankPressureKPA: 0,
    deliveryPressureKPA: 0,
    reservePressureKPA: 0,
    leftBankEmpty: 0,
    rightBankEmpty: 0,
    timeOnCurrentBank: 0,
    timeOnPreviousBank: 0,
    mainCategoryID: '',
    mainCategoryName: '',
    installBaseID: '',
    installBaseName: '',
    installBaseLocation: '',
    installBaseSerialNumber: '',
    systemRunHours: 0,
    unit1Hours: 0,
    unit2Hours: 0,
    dewpoint: 0,
    coLevel: 0,
    receiverPressure: 0,
    dryer1Mode: '',
    dryer2Mode: '',
    leftBankPressurePSI: 0,
    rightBankPressurePSI: 0,
    deliveryPressurePSI: 0,
    leftBankSelectLatch: true,
    reserveInUseAlarm: false,
    reserveLowAlarm: false,
    secondaryLowAlarm: false,
    changeoverAlarm: false,
    dewpointAlarm: false,
    gasType: '',
    date: '',
    device: {}
};

export const initialTae: Itae = {
    leftBankPressureBar: 0,
    rightBankPressureBar: 0,
    deliveryPressureBar: 0,
    reservePressureBar: 0,
    leftBankPressurePSI: 0,
    rightBankPressurePSI: 0,
    deliveryPressurePSI: 0,
    reservePressurePSI: 0,
    leftBankPressureKPA: 0,
    rightBankPressureKPA: 0,
    deliveryPressureKPA: 0,
    reservePressureKPA: 0,
    leftBankEmpty: 0,
    rightBankEmpty: 0,
    leftBankSelectLatch: true,
    reserveInUseAlarm: false,
    reserveLowAlarm: false,
    secondaryLowAlarm: false,
    changeoverAlarm: false,
    gasType: '',
    timeOnCurrentBank: 0,
    timeOnPreviousBank: 0,
    installBaseID: '',
    serialNumber: '',
    date: '',
    systemHours: 0,
    unit1RunningHours: 0,
    unit2RunningHours: 0,
    lagAlarm: false,
    dewpointAlarm: false,
    coAlarm: false,
    dryer1Mode: '',
    dryer2Mode: '',
    dewpointValue: 0,
    coValue: 0,
    receiverPressure: 0,
    id: '',
    isDeleted: false
};

export const initialChart = {
    data: [],
    label: '',
    unit: dataUnitEnum.none,
    format: dataFormatEnum.none
};

export const initialAnalyticsSmartlink: IanalyticsSmartlink = {
    mainCategoryID: '',
    mainCategoryName: '',
    installBaseID: '',
    installBaseName: '',
    installBaseLocation: '',
    installBaseSerialNumber: '',
    latestValues: [],
    unit1Graph: [],
    unit2Graph: [],
    updateDate: '',
    photoUrl: '',
    device: {}
};

export const initialSuggestedPart: IsuggestedPart = {
    adminComments: '',
    createDate: '',
    id: '',
    installBaseID: '',
    installBaseQuantity: 0,
    isDeleted: false,
    part: initialPart,
    partID: '',
    product: initialProduct,
    productID: '',
    productQuantity: 0,
    updateDate: ''
};

export const initialJobComment = {
    id: '',
    jobID: '',
    userID: '',
    isDeficiency: false,
    isDeleted: false,
    text: '',
    codeID: '',
    isResolved: true
};

export const initialDeficiencyCode: IdeficiencyCode = {
    description: '',
    name: '',
    id: '',
    isDeleted: false,
    standardID: ''
};
export const initialLeadActivity: IleadActivity = {
    type: leadActivityTypesEnum.email,
    otherType: '',
    notes: '',
    followUpNote: '',
    followUpScheduled: '',
    leadID: '',
    id: '',
    activityDate: '',
    isDeleted: false,
    userID: '',
    followUpSent: '',
    user: initialUser
};

/* initialState */
const initialState = {
    countries: [],
    ajaxCallsInProgress: 0,
    offline: {
        busy: false,
        online: true,
        retryCount: 0,
        retryScheduled: false,
        outbox: [],
        lastTransaction: 0
    },
    config: initialConfig,
    user: initialUser,
    toastr: { toastrs: [] },
    redirect: {
        redirectToReferrer: false,
        pathname: '/'
    },
    manageUserQueue: {
        totalPages: 1,
        userQueuesByID: {},
        tableFilters: initialTableFilters,
        selectedUserQueue: initialUserQueue
    },
    manageUser: {
        usersByID: {},
        selectedUser: initialUser,
        totalPages: 1,
        tableFilters: initialTableFilters,
        activeLocalCountry: ''
    },
    manageJob: {
        totalPages: 1,
        nextJobNumber: '',
        jobsByID: {},
        openJobsForWorkOrdersByID: {},
        fseUsersByID: {},
        tableFilters: initialTableFilters,
        selectedJob: initialJob,
        jobWorkOrdersByID: {}
    },
    manageBrand: {
        manageBrandsByID: {},
        selectedBrand: initialBrand,
        totalPages: 1,
        tableFilters: initialTableFilters
    },
    manageDocumentType: {
        documentTypesByID: {},
        showEditDocumentTypeModal: false,
        selectedDocumentTypeID: ''
    },
    manageDocument: {
        documentsByID: {},
        totalPages: 1,
        showEditDocumentModal: false,
        showEditVersionModal: false,
        tableFilters: initialTableFilters,
        selectedParentID: '',
        selectedDocumentID: '',
        documentFormValues: {},
        versionFormValues: {}
    },
    manageLeads: {
        leadsByID: {},
        leadUsersByID: {},
        leadInstallBasesByID: {},
        totalPages: 1,
        tableFilters: initialTableFilters,
        showEditLeadModal: false,
        showLeadActivityModal: false,
        showEditLeadActivityModal: false,
        leadActivitiesByID: {},
        selection: [],
        selectedLead: initialLead,
        selectedLeadActivity: initialLeadActivity
    },
    manageCustomerQuotes: {
        quotesByID: {},
        tableFilters: initialTableFilters,
        selectedQuote: initialQuote,
        showEditQuoteModal: false,
        showQuoteDetailsModal: false,
        showSalesQuoteModal: false,
        totalPages: 1
    },
    manageWorkOrder: {
        workOrdersByID: {},
        visibleWorkOrdersByID: {},
        technicians: [],
        totalPages: 1,
        showEditWorkOrderModal: false,
        showEditPartModalForm: false,
        showWorkOrderCloseModal: false,
        tableFilters: initialTableFilters,
        selectedWorkOrderID: '',
        selectedPartID: '',
        workOrderFormValues: {},
        preventativeMaintenanceChecklistsByID: {},
        showConfirmSelectJobModal: false,
        selection: [],
        partFormValues: {},
        selectedFacilityIDForConfirmSelectJob: '',
        fsesWithWorkOrders: []
    },
    manageAlert: {
        totalPages: 1,
        alertsByID: {},
        showEditAlertModal: false,
        tableFilters: initialTableFilters,
        selectedAlertID: '',
        alertFormValues: {}
    },
    manageCode: {
        totalPages: 1,
        deficiencyCodesByID: {},
        showEditCodeModal: false,
        tableFilters: initialTableFiltersFrontEndPaging,
        selectedCodeID: '',
        codeFormValues: {}
    },
    manageFacility: {
        contacts: {},
        selectedContactID: '',
        foundUsersOrContactsByID: {},
        facilityFormValues: {},
        tableFilters: initialTableFiltersFrontEndPaging,
        selectedFacilityID: '',
        facilityContractFormValues: {},
        showFacilityContractModal: false,
        selectedContact: initialContact
    },
    manageReport: {
        totalPages: 1,
        defaultReportsByID: {},
        selectedReport: initialReport,
        selectedDefaultReportID: '',
        showEditReportModal: false,
        tableFilters: initialTableFilters
    },
    manageLocation: {
        totalPages: 1,
        visibleLocations: [],
        showEditLocationModal: false,
        tableFilters: initialTableFilters
    },
    customersWithFacilities: {},
    manageTeam: {
        teamMembersByID: {},
        selectedUserID: '',
        showEditTeamModal: false,
        editUserFormValues: {},
        totalPages: 1,
        tableFilters: initialTableFilters
    },
    manageInventory: {
        showEditProductModal: false,
        showEditInstallModal: false,
        showShoppingCartModal: false,
        showInstallContactModal: false,
        showSearchNewProductsModal: false,
        showImportInstallModal: false,
        showMPResultModal: false,
        showMPResultHistoryModal: false,
        showMPResultNotesModal: false,
        totalPages: 1,
        productsByID: {},
        installBasesByID: {},
        cart: {
            addedIDs: [],
            productsByID: {}
        },
        tableFilters: {
            ...initialTableFiltersFrontEndPaging,
            hideVirtualToggle: true
        },
        searchNewProductsSelectedProduct: initialProduct,
        newProducts: {},
        selectedProductID: '',
        selectedInstallBaseID: '',
        productSearchFormValues: {},
        installFormValues: {},
        searchNewProductInstallBatchMode: {
            isBatchMode: false,
            installBaseIDs: []
        }
    },
    manageProduct: {
        showEditProductModal: false,
        tableFilters: initialTableFilters,
        productsByID: {},
        totalPages: 1
    },
    productInfo: {
        brands: {},
        allBrands: {}, // New property for all brands (unfiltered)
        productTypes: {},
        mainCategories: {},
        powers: {},
        standards: {},
        subcategories: {},
        systemSizes: {},
        brandOptions: [],
        allBrandOptions: [],
        originOptions: [],
        productTypeOptions: [],
        mainCategoryOptions: [],
        powerOptions: [],
        standardOptions: [],
        subcategoryOptions: [],
        systemSizeOptions: []
    },
    manageProductQueue: {
        showApproveProductModal: false,
        totalPages: 1,
        productQueueByID: {},
        mergeableProductsByID: {},
        tableFilters: initialTableFilters
    },
    manageTae: {
        taeByID: {},
        selectedItemID: '',
        tableFilters: initialTableFiltersFrontEndPaging,
        showEditModal: false
    },
    training: {
        cart: {
            addedIDs: [],
            productsByID: {}
        },
        showShoppingCartModal: false,
        coursesByID: {},
        lessonsByID: {},
        lesson: initialLesson,
        quizzesByID: {},
        quiz: initialQuiz,
        lessonProgress: {},
        purchasedTraining: [],
        quizAnswers: [],
        quizView: {
            quizComplete: false,
            inProgressQuizID: '',
            startTime: ''
        },
        quizLink: { link: '' }
    },
    manageTraining: {
        trainingProgress: {},
        tableFilters: initialTableFilters,
        totalPages: 1
    },
    manageMeasurementPointLists: {
        totalPages: 1,
        measurementPointListsByID: {},
        measurementPointsByID: {},
        selectedMeasurementPointList: initialMeasurementPointList,
        showEditMeasurementPointListModal: false,
        showEditMeasurementPointModal: false,
        showEditMeasurementPointTabModal: false,
        showEditMeasurementPointListTestProceduresModal: false,
        tableFilters: initialTableFilters,
        selectedTabID: '',
        selectedMeasurementPoint: initialMeasurementPoint
    },
    measurementPointResults: {
        measurementPointResultsByID: {},
        selectedResult: initialMeasurmentPointResult,
        previousResult: initialMeasurmentPointResult,
        historicalResultID: ''
    },
    facilities: {},
    syncStatus: {
        fseUsersUpdated: 0,
        allContactsUpdated: 0
    },
    showSideMenu: false,
    showEditFacilityModal: false,
    showEditProfileModal: false,
    showSecurityFunctionsModal: false,
    showFacilityContactModal: false,
    showFacilityContractModal: false,
    selectedFacilityID: '',
    dashboardAnalytics: {
        data: {
            inspection: [],
            pmp: { totalWorkOrders: 0, data: { open: [], closed: [] } },
            tae: [],
            taeDashboard: [],
            smartlinks: [],
            quote: initialAnalyticsQuote
        }
    },
    manageJobComment: {
        jobCommentsByID: {},
        selectedJobComment: initialJobComment,
        showEditJobCommentModal: false,
        filteredJobComments: [],
        tableFilters: initialTableFiltersFrontEndPaging
    },
    rawUsersByID: {},
    securityUsersByID: {},
    showAlertModal: false,
    alertsByID: {},
    tutorial: {
        viewedTours: [],
        run: false
    },
    manageAssetPhotos: {
        totalPages: 1,
        tableFilters: initialTableFilters,
        photos: {},
        photosByProduct: {}
    },
    managePart: {
        jobParts: {}
    },
    manageSuggestedParts: {
        totalPages: 1,
        suggestedParts: {},
        showEditSuggestedPartModal: false,
        tableFilters: initialTableFilters,
        selectedSuggestedPart: initialSuggestedPart
    },
    sapJobMappings: {
        sapJobMappingsByID: {}
    }
};

export const emptyTile: Itile = {
    title: '',
    src: '',
    srcBanner: '',
    color: 'blue',
    width: 359,
    height: 136,
    url: '',
    securityFunctions: [],
    description: '',
    parentKey: 0,
    order: 100
};

export const initialPhoto: Iphoto = {
    comments: '',
    createDate: '',
    file: '',
    fileType: 0,
    height: 0,
    id: '',
    installBase: initialInstallBase,
    installBaseID: '',
    isDeleted: false,
    isInReport: false,
    jobID: '',
    lightboxUrl: '',
    name: '',
    originalUrl: '',
    product: initialProduct,
    productID: '',
    src: '',
    thumbnailUrl: '',
    updateDate: '',
    width: 0
};

export default initialState;
